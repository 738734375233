// Bar
.bar {
  @include transition(background-color, padding-top, padding-bottom, transform);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  z-index: 100;
  
  &:after {
    @include transition(opacity);
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    opacity: 0;
    background: $color-alto;
  }

  > .cnt {
    @include flexbox-display;
    @include flexbox-flow(row nowrap);
    @include flexbox-align-items(center);
    @include flexbox-justify-content(space-between);
  }

  &__logo {
    height: 30px;

    a {
      display: block;
      color: $color-white;
      font-size: 35px;

      svg {
        display: block;
      }

      &:hover {
        color: $color-atomic-tangerine;
      }
    }
  }

  &__navigation {
    display: none;
    @include breakpoint(l) {
      display: block;
      position: relative;
      z-index: 3;
    }

    h2 {
      display: none;
    }

    > ul {
      @include flexbox-display;
      @include flexbox-flow(row nowrap);

      > li {
        position: relative;

        &:not(:last-child) {
          margin-right: 40px;
        }

        a {
          display: block;
          color: $color-white;
          line-height: 40px;
          position: relative;
          white-space: nowrap;
          font-size: 15px;
          font-weight: 500;

          &:hover {
            color: $color-atomic-tangerine;
          }

          .icon {
            margin-left: 5px;
          }

          &.is-active {
            color: $color-atomic-tangerine;

            &:after {
              content: '';
              position: absolute;
              height: 2px;
              width: 100%;
              bottom: 0;
              left: 0;
              background: $color-atomic-tangerine;
            }
          }
        }

        > ul {
          @include transition(visibility, opacity, transform, background, border);
          position: absolute;
          right: 0;
          top: 100%;
          white-space: nowrap;
          padding: 10px 25px;
          margin-right: -1.5rem;
          visibility: hidden;
          opacity: 0;
          transform: translateY(10px);
          border-radius: 4px;
          box-shadow: 0 5px 10px rgba($color-black, 0.05);
          background: $color-selago;
          border: 1px solid $color-alto;
          pointer-events: none;
          // background: $color-atomic-tangerine;
          // border: 1px solid $color-atomic-tangerine;

          a {
            line-height: 32px;

            color: $color-dolphin;

            // &:hover {
            //   color: $color-mortar;
            // }

            // &.is-active {
            //   color: $color-mortar;
            // }

            &:after {
              display: none;
            }
          }
        }

        &:hover {
          ul {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            pointer-events: all;
          }
        }
      }
    }

    .button {
      width: auto;
      margin-top: 0;
    }
  }

  &__menu-trigger {
    @include button-reset;
    @include transition(color);
    color: $color-white;
    font-size: 30px;
    width: 30px;
    height: 26px;
    z-index: 4;
    position: relative;
    @include breakpoint(l) {
      display: none;
    }

    .icon {
      position: absolute;
      top: 50%;
      display: block;
      transform: translateY(-50%);
      overflow: visible !important;
    }

    &-line {
      @include transition(transform, opacity);
      transform-origin: center;

      &:nth-child(1) {
        transform: translate3d(0, 3.2em, 0);
      }
      &:nth-child(2) {
        transform-origin: right center;
        transform: scaleX(0.8);
      }
      &:nth-child(3) {
        transform: translate3d(0, -3.2em, 0);
      }
    }

    &.is-active {
      color: $color-white;

      .bar__menu-trigger-line {
        &:nth-child(1) {
          transform: rotate(-45deg) translate3d(0, 0, 0);
        }
        &:nth-child(2) {
          transform: scaleX(0);
        }
        &:nth-child(3) {
          transform: rotate(45deg) translate3d(0, 0, 0);
        }
      }
    }

    &.is-disabled {
      color: $color-white;

      .bar__menu-trigger-line {
        &:nth-child(1) {
          transition: none;
          transform: translate3d(0, 3.2em, 0);
        }
        &:nth-child(2) {
          transition: none;
          transform: scaleX(1);
        }
        &:nth-child(3) {
          transition: none;
          transform: translate3d(0, -3.2em, 0);
        }
      }
    }
  }

  &.is-active {
    background-color: $color-selago;

    &:after {
      opacity: 1;
    }

    .bar {
      &__navigation {
        a:not(.button) {
          color: $color-dolphin;

          &:hover {
            color: $color-atomic-tangerine;
          }

          &.is-active {
            color: $color-atomic-tangerine;
          }
        }

        ul li ul {
          background: $color-selago;
          border-color: $color-alto;
          box-shadow: 0 
        }
      }

      &__menu-trigger {
        color: $color-shark;

        &.is-active {
          color: $color-white;
        }

        &.is-disabled {
          color: $color-shark;
        }
      }

      &__logo {
        a {
          color: $color-shark;

          &:hover {
            color: $color-atomic-tangerine;
          }
        }
      }
    }
  }

  &__distancer {
    @extend .distance--bottom-2xl;
    height: 70px;
    @include breakpoint(l) {
      height: 80px;
    }

    .pjax-container.payment-index & {
      @extend .distance--bottom-none;
    }
  }

  &__extra {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    pointer-events: none;

    .bar--style-submenu {
      @include transition(transform, opacity);
      background-color: $color-selago;
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  &.is-category {
    padding: 14px 0;

    &:after {
      //opacity: 0;
    }

    .bar {
      &__extra {
        pointer-events: all;

        .bar--style-submenu {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  &--no-header {
    background-color: $color-selago;
    
    &:after {
      opacity: 1;
    }

    .bar {
      &__navigation {
        a:not(.button) {
          color: $color-dolphin;

          &:hover {
            color: $color-atomic-tangerine;
          }

          &.is-active {
            color: $color-atomic-tangerine;
          }
        }
      }

      &__menu-trigger {
        color: $color-shark;

        &.is-active {
          color: $color-white;
        }
      }

      &__logo {
        a {
          color: $color-shark;

          &:hover {
            color: $color-atomic-tangerine;
          }
        }
      }
    }
  }

  &__category-wrapper {
    @extend .distance--bottom-2xl;
    position: relative;
    height: 50px;
    z-index: 2;
  }

  &--style {
    &-submenu {
      background: $color-selago;
      position: relative;
      padding: 0px;
      z-index: unset;

      &:after {
        opacity: 1;
        z-index: 1;
      }

      .cnt {
        position: relative;
        z-index: 2;
      }

      .bar {
        &__search {
          @include transition(width, background);
          @extend .flexbox--center-row;
          position: absolute;
          right: $cnt-spacing;
          top: 0;
          bottom: 0;
          background: $color-selago;
          z-index: 3;
          width: 18px;
          border-bottom: 1px solid $color-alto;
          overflow: hidden;
          @include breakpoint(m) {
            right: $cnt-spacing-m;
          }

          .icon {
            @include transition(color);
            font-size: 18px;
          }

          &-container {
            position: relative;
            width: 100%;
          }

          &-icon {
            @extend .flexbox--center;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            color: $color-dolphin;
          }

          &:hover {
            .bar__search-icon {
              color: $color-atomic-tangerine;
            }
          }

          &-clear {
            @include button-reset;
            @include transition(opacity);
            @extend .flexbox--center;
            background: #fff;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 3;
            opacity: 0;
            color: $color-dolphin;

            &:hover {
              color: $color-atomic-tangerine;
            }
          }

          &-field {
            @include transition(opacity);
            position: relative;
            padding-left: 28px;
            padding-right: 28px;
            font-size: 15px;
            pointer-events: none;
            border-bottom: none;
            display: block;
            width: 100%;
            z-index: 2;
            opacity: 0;
            @include breakpoint(m) {
              font-size: 16px;
            }
          }
        }

        &__wrapper {
          width: calc(100% - 50px);
          white-space: nowrap;
          @include breakpoint(m) {
            width: calc(100% - 58px);
          }
        }

        &__navigation {
          display: inline-block;
          vertical-align: top;

          > ul {
            > li {
              margin-right: 32px;
              @include breakpoint(m) {
                margin-right: 40px;
              }

              &.bar__navigation-margin-fix {
                margin-right: -32px;
                @include breakpoint(m) {
                  margin-right: -40px;
                }
              }

              > a {
                @include transition(line-height, color);
                line-height: 50px;

                .bar__extra & {
                  line-height: 42px;
                }
              }

              a {
                color: $color-dolphin;

                &.is-active {
                  color: $color-atomic-tangerine;
                }

                &:hover {
                  color: $color-atomic-tangerine;
                }
              }

              ul {
                min-width: 100%;
                top: calc(100% - 5px);
              }
            }
          }

          &-more {
            display: none;
          }
        }
      }

      &.is-active {
        .bar {
          &__search {
            width: calc(100% - #{$cnt-spacing * 2});
            background-color: $color-selago;
            @include breakpoint(m) {
              width: calc(100% - #{$cnt-spacing-m * 2});
            }

            &-clear {
              opacity: 1;
            }

            &-field {
              opacity: 1;
              pointer-events: all;
            }

            &:hover {
              .bar__search-icon {
                color: $color-dolphin;
              }
            }
          }
        }
      }
    }
  }
}