// Distance
.distance {
  $sizes: (
    none: 0px,
    xxs:  8px,
    xs:   16px,
    s:    24px,
    m:    32px,
    l:    48px,
    xl:   64px,
    2xl:  96px,
    3xl: 128px,
    4xl: 160px,
  );

  &--bottom {
    @each $size, $value in $sizes {
      &-#{$size} {
        &:not(:last-child) {
          margin-bottom: $value * 0.7;
          @include breakpoint(m) {
            margin-bottom: $value * 0.85;
          }
          @include breakpoint(l) {
            margin-bottom: $value;
          }
        }
      }
    }
  }
}