// Transition
@mixin transition($property-array...) {
  $properties: null;
  @each $property in $property-array {
    @if $property != "none" {
      $properties: $properties, $property $animation-duration $animation-easing;
    }
    @else {
      transition: none;
    }
  }
  transition: $properties;
}
