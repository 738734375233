// Scroll to top
.scroll-to-top {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 0;
  z-index: 95;

  .cnt {
    position: relative;
    height: 0;
  }

  &__button {
    @include transition(transform);
    position: absolute;
    right: $cnt-spacing;
    bottom: 2rem;
    transform: translate3d(0, 4.5rem, 0);
    @include breakpoint(m) {
      transform: translate3d(0, 5rem, 0);
      right: $cnt-spacing-m;
    }
    @include breakpoint(xxl) {
      bottom: $cnt-spacing-m * 2;
      transform: translate3d(0, 7rem, 0);
    }

    body.enable-scroll-to-top & {
      transform: translate3d(0, 0, 0);
    }

    .button {
      @extend .flexbox--center;
      width: 38px;
      height: 38px;
      font-size: 11px;
      padding: 0;
      @include breakpoint(m) {
        width: 44px;
        height: 44px;
        font-size: 13px;
      }

      .icon {
        display: block;
      }
    }
  }
}