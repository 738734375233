// Breakpoint
@mixin breakpoint($name) {
  $breakpoints: (
    's':   (min-width: $breakpoint-s),
    'm':   (min-width: $breakpoint-m),
    'l':   (min-width: $breakpoint-l),
    'xl':  (min-width: $breakpoint-xl),
    'xxl': (min-width: $breakpoint-2xl),
  );
  @if map-has-key($breakpoints, $name) {
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }
}