// Limit
.limit {
  &--size {
    &-s {
      max-width: 75%;
      @include breakpoint(m) {
        max-width: 50%;
      }
      @include breakpoint(l) {
        max-width: 25%;
      }
    }

    &-m {
      @include breakpoint(m) {
        max-width: 66%;
      }
      @include breakpoint(l) {
        max-width: 50%;
      }
    }

    &-l {
      @include breakpoint(l) {
        max-width: 75%;
      }
    }
  }
}