// Dropdown
.dropdown {
  position: relative;
  display: block;
  z-index: 90;

  &__container {
    position: relative;
  }

  .button {
    @extend .flexbox--center-row;
    @include button-reset;
    min-width: 100%;

    .icon--caret-down {
      @include transition(color);
      position: absolute;
      top: 50%;
      font-size: 12px;
      margin-top: -6px;
    }
  }

  &--style {
    &-default {
      .dropdown {
        &__container {
          background: $color-white;
          color: $color-haiti;
          box-shadow: 0 4px 8px 0 rgba($color-black, 0.05);
          @include breakpoint(m) {
            box-shadow: 0 5px 10px 0 rgba($color-black, 0.05);
          }
        }
      }
    }
  }

  &--size {
    &-s {
      height: 28px;

      .dropdown {
        &__container {
          border-radius: 12px;
        }
      }

      .button {
        font-size: 14px;
        height: 28px;
        line-height: 28px;
        padding: 0 2.7rem 0 1.2rem;
      }

      .icon--caret-down {
        right: 1.2rem;
      }
    }
  }

  &__menu {
    @include flexbox-display;
    @include flexbox-flow(column nowrap);
    position: relative;
    height: 0;
    overflow: hidden;

    &.is-active {
      height: auto;
    }
  }

  &__option {
    min-width: 100%;
    color: $color-dolphin;

    &:hover {
      color: $color-haiti;
    }

    &.is-active {
      display: none !important;
    }
  }
}
