// Set CSS box model
// Remove focus from elements
* {
  cursor: inherit;

  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
  }
}

// Prevent iOS and MS text size adjust after orientation change, without disabling user zoom
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

// Remove default margin
body {
  margin: 0;
  cursor: default;
}

// Correct "block" display not defined for any HTML5 element
// Correct "block" display not defined for "details" or "summary" in IE 10/11 and Firefox
// Correct "block" display not defined for "main" in IE 11
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary  {
  display: block;
}

// Remove the gray background color from active links in IE 10
a {
  background: transparent;
}

// Remove default margin
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
}

// Hide empty paragraph
p:empty {
  display: none;
}

// Address odd "em"-unit font size rendering in all browsers
code,
kbd,
pre,
samp {
  font-family: "Consolas", "monaco", "monospace";
  font-size: 1rem;
}

// Contain overflow in all browsers
pre {
  overflow: auto;
}

// Address inconsistent and variable font size in all browsers
small {
  font-size: 0.75em;
}

// Prevent "sup" and "sub" affecting "line-height" in all browsers
sup,
sub {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

// Superscript
sup {
  top: -0.5em;
}

// Subscript
sub {
  bottom: -0.25em;
}

// Remove border when inside "a" element in IE 10
img {
  border: 0;
}

// Remove default margin
figure {
  margin: 0;
}

// Remove default padding and margin
ul,
ol,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

// Remove default list style
li {
  list-style: none;
}

// Correct overflow not hidden in IE 10/11
svg {
  &:not(:root) {
    overflow: hidden;
  }
}

// Prevent modern browsers from displaying "audio" without controls
audio {
  &:not([controls]) {
    display: none;
  }
}

// Remove default margin
form {
  margin: 0;
}

// Remove default border
fieldset {
  border: 0;
}

// Remove default padding and border
legend {
  border: 0;
  padding: 0;
}

// Correct font properties not being inherited
// Correct color not being inherited
// Address inconsistent "text-transform" inheritance for "button" and "select"
// Address margins set differently in Firefox 4+, Safari, and Chrome
// Remove inner padding and border in Firefox 4+
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  text-transform: inherit;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

// Improve usability and consistency of cursor style between image-type "input" and others
// Correct inability to style clickable "input" types in iOS
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

// Re-set default cursor for disabled elements
button[disabled],
input[disabled] {
  cursor: default;
}

// Remove default padding
// Address "overflow" set to "hidden" in IE 10/11
button {
  overflow: visible;
  padding: 0;
}

// Remove Safari and Chrome appearance
// Remove Firefox appearance
select,
textarea,
input[type="url"],
input[type="tel"],
input[type="text"],
input[type="date"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="password"] {
  -moz-appearance: none;
  -webkit-appearance: none;
}

// Set vertical alignment to top
// Remove default vertical scrollbar in IE 10/11
// Allow only vertical resizing of textareas
textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top;
}

// Remove arrows default styled in Firefox for input number
// Remove arrows default styled in Safari and Chrome for input number
input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

// Remove inner padding and search cancel button in Safari and Chrome on OS X
// Safari (but not Chrome) clips the cancel button when the search input has padding (and "textfield" appearance)
input[type="search"] {
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

// Remove excess padding in IE 10
input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}

// Remove most spacing between table cells
table {
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    padding: 0;
  }
}

input, textarea {
  cursor: text;
}

a, button, input[type="sybmit"] {
  cursor: pointer;
}

// Message for IE7 users about outdated browser
.browsehappy {
  background: #c00;
  color: #fff;
  left: 0;
  padding: 1.25rem;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 999999;

  a {
    color: #fff;
    text-decoration: underline;
  }
}