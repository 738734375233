// Paragraph
.paragraph {
  &--size {
    &-s {
      font-size: 14px;
      @include breakpoint(m) {
        font-size: 15px;
      }
    }

    &-m {
      font-size: 14px;
      @include breakpoint(m) {
        font-size: 16px;
      }
    }

    &-l {
      font-size: 16px;
      @include breakpoint(m) {
        font-size: 18px;
      }
    }

    &-xl {
      font-size: 18px;
      @include breakpoint(m) {
        font-size: 20px;
      }
    }

    &-27 {
      font-size: 22px;
      @include breakpoint(m) {
        font-size: 27px;
      }
    }
  }

  &--transform {
    &-uppercase {
      text-transform: uppercase;
    }
  }

  &--weight {
    &-regular {
      font-weight: 400;
    }

    &-bold {
      font-weight: 700;
    }
  }

  &--spacing {
    &-s {
      > * {
        &:not(:last-child) {
          margin-bottom: 0.75em;
        }
      }
    }

    &-m {
      > * {
        &:not(:last-child) {
          margin-bottom: 1em;
        }
      }
    }

    &-l {
      > * {
        &:not(:last-child) {
          margin-bottom: 1.25em;
        }
      }
    }
  }
}
