// Team
.team {
  @extend .distance--bottom-3xl;

  &__filter {
    @extend .distance--bottom-l;
    ul {
      @include flexbox-display;
      @include flexbox-flow(row wrap);
      @include flexbox-justify-content(center);
      font-size: 14px;
      @include breakpoint(l) {
        @include flexbox-flow(row nowrap);
        @include flexbox-justify-content(flex-start);
      }

      li {
        a {
          color: $color-mountain-mist;
          white-space: nowrap;

          &.is-hover, &:hover {
            color: $color-atomic-tangerine;
          }

          &.is-active {
            color: $color-persimmon;  
          }

          &.is-disabled {
            color: $color-mountain-mist;
          }

          .team.is-all &:not([region="all"]) {
            &.is-active {
              color: $color-persimmon;  
            }

            &.is-hover, &:hover {
              color: $color-atomic-tangerine;
            }

            &.is-disabled {
              color: $color-mountain-mist;
            }
          }
        }

        &:not(:last-child) {
          margin-right: 16px;
          margin-bottom: 4px;
          @include breakpoint(l) {
            margin-right: 50px;
          }
        }
      }
    }
  }

  &__map {
    @extend .distance--bottom-s;
    position: relative;
    z-index: 1;
    margin-left: - $cnt-spacing / 2;
    margin-right: - $cnt-spacing / 2;
    @include breakpoint(s) {
      margin-left: 0;
      margin-right: 0;
    }

    &-size {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 47.8%;
    }

    .icon--world-map {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &-region {
      @include transition(color);
      color: $color-alto;
      cursor: pointer;

      &.is-hover, &:hover {
        color: $color-atomic-tangerine;
      }

      &.is-active {
        color: $color-persimmon;
      }

      &.is-disabled {
        color: $color-alto;
      }

      .team.is-all & {
        &.is-active {
          color: $color-persimmon;  
        }

        &.is-hover, &:hover {
          color: $color-atomic-tangerine;
        }

        &.is-disabled {
          color: $color-alto;
        }
      }
    }
  }

  &__sort {
    @extend .distance--bottom-m;
    position: relative;

    .form__field {
      @extend .flexbox--center-row;

      .form__label {
        color: $color-mountain-mist;
        text-transform: none;
        margin: 0 10px 0 auto;
      }
    }
  }

  &__members {
    position: relative;
    overflow: hidden;

    &-item {
      padding: 0 14px 2.8rem;
      @include breakpoint(m) {
        padding: 0 20px 3.4rem;
      }
      @include breakpoint(l) {
        padding: 0 20px 4rem;
      }

      &-wrapper {
        margin: 0 auto;
        max-width: 180px;
        @include breakpoint(m) {
          margin: 0;
          max-width: none;
        }
      }

      &-image {
        @extend .distance--bottom-l;
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 100%;
        background-color: $color-white;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0 0.5rem 1rem rgba($color-lynch, 0.1);
        @include breakpoint(m) {
          box-shadow: 0 1rem 1.5rem rgba($color-lynch, 0.1);
        }

        &-photo, &-avatar, &-contact {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
        }

        &-avatar {
          @include cover;
          border-radius: 10px;

          &.is-male {
            background-image: url('../img/team/blank-avatar-male.svg');
          }

          &.is-female {
            background-image: url('../img/team/blank-avatar-female.svg');
          }
        }

        &-photo {
          @include cover;
          @include transition(transform);
          border-radius: 10px;
          backface-visibility: hidden;
          transform: translateZ(0);
        }

        &-contact {
          @extend .flexbox--center;
          @include transition(opacity, visibility);
          background-image: linear-gradient(to bottom right, rgba($color-bittersweet, 0.9), rgba($color-amaranth, 0.9));
          font-size: 20px;
          opacity: 0;
          visibility: hidden;

          div {
            @extend .flexbox--center-row;
          }

          a {
            display: block;
            color: $color-white;

            &:hover {
              color: rgba($color-white, 0.5);
            }

            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }

        &:hover {
          .team__members-item-image {
            &-photo {
              transform: translateZ(0) scale(1.1);
            }

            &-contact {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      &-name {
        margin-bottom: 4px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        @include breakpoint(m) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      &-position {
        @extend .distance--bottom-xs;
        font-size: 13px;
        line-height: 16px;
        @include breakpoint(m) {
          font-size: 14px;
          line-height: 17px;
        }
      }

      &-location {
        font-size: 12px;
        line-height: 15px;
        color: $color-mountain-mist;
        @include breakpoint(m) {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }

    &-offset-fix {
      margin-bottom: -2.8rem;
      @include breakpoint(m) {
        margin-bottom: -3.4rem;
      }
      @include breakpoint(l) {
        margin-bottom: -4rem; 
      }
    }
  }

  &__scroll-to {
    position: absolute;
    left: 0;
    top: -130px;
    height: 0;
    @include breakpoint(l) {
      top: -140px;
    }
  }
}