// Horizontal
@mixin gradient-horizontal($left, $right) {
  background: $left;
  background: linear-gradient(to right, $left 0%, $right 100%);
}

// Vertical
@mixin gradient-vertical($top, $bottom) {
  background: $top;
  background: linear-gradient(to bottom, $top 0%, $bottom 100%);
}