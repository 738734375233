// Carousel
.carousel {
  position: relative;

  &__wrapper {
    background-color: $color-mountain-mist;
    position: relative;
    overflow: hidden;
  }

  &__slide {
    @include cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    &.is-active {
      display: block;
      z-index: 1;
    }
  }

  &__dots {
    @extend .flexbox--center-row;
    @include flexbox-justify-content(center);

    > * {
      @include transition(background);
      cursor: pointer;
      width: 20px;
      height: 3px;
      background: $color-mountain-mist;

      &:hover {
        background: $color-atomic-tangerine;
      }

      &.is-active {
        background: $color-persimmon;
      }

      &:not(:last-child) {
        margin-right: 6px;
      }

      &:before {
        position: relative;
        display: block;
        content: '';
        height: 20px;
        width: 100%;
        top: -8px;
      }
    }
  }
}