// CTA
.cta {
  @include cover;
  color: $color-white;
  padding: 64px 0;
  position: relative;
  z-index: 10;
  @include breakpoint(m) {
    padding: 76px 0;
  }
  @include breakpoint(l) {
    padding: 88px 0;
  }

  &__text {
    @extend .paragraph--size-xl;
    @extend .distance--bottom-xs;
    font-weight: 600;
  }

  &__title {
    @extend .title--size-xl;
    @extend .distance--bottom-l;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
  }

  &--style {
    &-light {
      color: $color-haiti;
      background-size: auto 100%;
      background-position: right center;
      background-repeat: no-repeat;
      padding: 5.25rem 0;
      @include breakpoint(m) {
        padding: 6rem 0;
      }
      @include breakpoint(l) {
        padding: 7.5rem 0;
      }

      .cta {
        &__title {
          font-weight: 700;
        }
      }
    }

    &-story {
      color: $color-selago;
      background-color: $color-shark;
      background-position: top right;
      background-repeat: no-repeat;
      background-size: auto 500px;
      padding: 8.75rem 0 5.75rem;
      @include breakpoint(m) {
        background-size: auto 100%;
        background-position: center right;
        padding: 10.5rem 0 6.5rem;
      }
      @include breakpoint(l) {
        padding: 12.5rem 0 8rem;
      }

      .cta {
        &__title {
          @extend .limit--size-l;
          @extend .distance--bottom-l;
          line-height: 1.2;
          text-transform: none;
          font-weight: 700;
          font-size: 30px;
          @include breakpoint(m) {
            font-size: 36px;
          }
        }

        &__text {
          @extend .limit--size-l;
          @extend .paragraph--size-l;
          @extend .distance--bottom-xl;
          font-weight: 400;
        }

        &__buttons {
          text-align: center;
          @include breakpoint(s) {
            @include flexbox-display;
            @include flexbox-flow(row nowrap);
            @include flexbox-align-items(center);
            text-align: left;
          }

          .button {
            margin-bottom: 1rem;
            @include breakpoint(s) {
              margin-right: 1rem;
              margin-bottom: 0;
            }
            @include breakpoint(m) {
              margin-right: 2rem;
            }
          }
        }
      }
    }

    &-home-story {
      background-size: auto 1122px;
      background-position: center center;
      padding: 5.75rem 0;
      @include breakpoint(m) {
        background-position: center right;
        padding: 6.5rem 0;
      }
      @include breakpoint(l) {
        padding: 8rem 0;
      }

      .cta {
        &__big-title {
          @extend .distance--bottom-xl;
          font-size: 11.2vw;
          @include breakpoint(s) {
            font-size: 45px;
          }
          @include breakpoint(m) {
            font-size: 54px;
          }
          @include breakpoint(l) {
            font-size: 64px;
            max-width: 75%;
          }
        }

        &__list {
          @extend .distance--bottom-3xl;
          font-size: 18px;
          @include breakpoint(m) {
            font-size: 21px;
          }
          @include breakpoint(l) {
            max-width: 75%;
          }

          ul {
            li {
              margin-bottom: 5px;
              position: relative;
              padding-left: 1.5rem;
              @include breakpoint(m) {
                margin-bottom: 6px;
              }

              &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: '\b7';
              }
            }
          }
        }
      }
    }

    &-home-everything-different {
      .cta {
        &__title {
          @extend .distance--bottom-xs;
          font-weight: 700;
          text-transform: none;
        }

        &__text {
          &-2 {
            @extend .distance--bottom-m;
            @include breakpoint(m) {
              max-width: 75%;
            }
            @include breakpoint(l) {
              max-width: 50%;
            }
          }
        }
      }
    }
  }
}
