// Sorted alphabetically, '@' always on top, followed by '-'

// CSS  - double quote
// HTML - double quote
// JS   - single quote
// Php  - single quote

// Color names
// http://chir.ag/projects/name-that-color

// Block, Element, Modifier
// .block {}
// .block__element {}
// .block--modifier {}
// .block__element--modifier {}

// State classes
// .is-active
// .is-inactive
// .is-visible
// .is-hidden
// .is-disabled

// Font
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Roboto:300,400,500,700');

// Base
@import "base/variable";

//Framework / Functions
@import "framework/functions/math-round";

// Framework / Mixins
@import "framework/mixins/breakpoint";
@import "framework/mixins/button";
@import "framework/mixins/clear";
@import "framework/mixins/contain";
@import "framework/mixins/cover";
@import "framework/mixins/flexbox";
@import "framework/mixins/fontless";
@import "framework/mixins/ellipsis";
@import "framework/mixins/gradient";
@import "framework/mixins/inline-block";
@import "framework/mixins/placeholder";
@import "framework/mixins/transition";

// Framework / Base
@import "framework/base/document";
@import "framework/base/normalize";

// Framework / Utilities
@import "framework/utilities/align";
@import "framework/utilities/cnt";
@import "framework/utilities/margin";
@import "framework/utilities/grid";
@import "framework/utilities/svg-icons";
@import "framework/utilities/scroll-reveal";

// Theme / Components
@import "theme/components/filterable";
@import "theme/components/article";
@import "theme/components/background-grid";
@import "theme/components/bar";
@import "theme/components/tooltip";
@import "theme/components/blog";
@import "theme/components/carousel.scss";
@import "theme/components/content-switch";
@import "theme/components/dribbble";
@import "theme/components/cta";
@import "theme/components/divider";
@import "theme/components/error";
@import "theme/components/pagination";
@import "theme/components/infinite-scroll";
@import "theme/components/loader";
@import "theme/components/modal";
@import "theme/components/alert";
@import "theme/components/open-source";
@import "theme/components/payment";
@import "theme/components/scroll-to-top";
@import "theme/components/section";
@import "theme/components/share";
@import "theme/components/stories";
@import "theme/components/team";
@import "theme/components/text-slider";

// Theme / Form
@import "theme/form/button";
@import "theme/form/checkbox";
@import "theme/form/file-upload";
@import "theme/form/dropdown";
@import "theme/form/field";
@import "theme/form/form";

// Theme / Layout
@import "theme/layout/footer";
@import "theme/layout/header";

// Theme / Utilities
@import "theme/utilities/color";
@import "theme/utilities/distance";
@import "theme/utilities/flexbox";
@import "theme/utilities/limit";
@import "theme/utilities/paragraph";
@import "theme/utilities/no-select";
@import "theme/utilities/title";

// Theme / Vendor
@import "theme/vendor/prism";

// Z-indexes
// cta: 10
// dropdown: 90
// go-to-top: 95
// bar: 100
// modal: 200
// alert: 250
// loader: 300
