// Stories
.stories {
  &__item {
    @extend .distance--bottom-2xl;
    position: relative;
    display: block;
    z-index: 1;
    color: $color-shark;
    @include breakpoint(m) {
      padding: 50px 0;
    }
    @include breakpoint(l) {
      padding: 60px 0;
    }
    @include breakpoint(xxl) {
      padding: 72px 0;
    }

    &:before {
      @include breakpoint(m) {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100vw;
        background: $color-aqua-haze;
        z-index: -1;
      }
    }

    &-content {
      @include flexbox-display;
      @include flexbox-flow(column nowrap);
      @include flexbox-justify-content(space-between);
      position: relative;
      z-index: 2;
      @include breakpoint(m) {
        height: 312px;
        width: 33.333%;
      }
      @include breakpoint(l) {
        width: 25%;
        height: 348px;
      }
      @include breakpoint(xxl) {
        height: 404px;
      }
    }

    &-category {
      font-size: 15px;
      margin-bottom: 16px;
      @include breakpoint(m) {
        margin-bottom: 0;
      }
    }

    &-title {
      font-weight: 400;
      font-size: 24px;
      margin-bottom: 16px;
      line-height: 1.25;
      @include breakpoint(m) {
        font-size: 25px;
        margin-bottom: 36px;
      }
      @include breakpoint(l) {
        font-size: 28px;
      }
      @include breakpoint(xxl) {
        margin-bottom: 48px;
      }

      a {
        display: block;
      }

      span {
        font-weight: 700;
      }
    }

    &-link {
      @extend .flexbox--center-row;
      font-size: 16px;
      white-space: nowrap;
      margin-bottom: 2rem;
      @include breakpoint(m) {
        margin-bottom: 0;
      }
      @include breakpoint(l) {
        font-size: 18px;
      }

      .icon {
        @include transition(transform);
        font-size: 11px;
        margin-left: 16px;
        @include breakpoint(l) {
          font-size: 12px;
          margin-left: 20px;
        }
      }

      &:hover {
        .icon {
          transform: translateX(15px);
        }
      }
    }

    &-device {
      position: relative;
      z-index: 1;
      pointer-events: none;
      @include breakpoint(m) {
        position: absolute;
      }

      &-graphic {
        background-size: 100% 100%;
        transform: none;
        @include breakpoint(l) {
          @include transition(transform);
          transform: scale(0.95);
        }
      }

      &-screen {
        background-color: $color-mountain-mist;
        position: absolute;

        a {
          pointer-events: all;
          display: block;
          width: 100%;
          height: 100%;

          > * {
            @include cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &:hover {
      color: $color-shark;

      .stories__item {
        &-device-graphic {
          @include breakpoint(l) {
            transform: scale(1);
          }
        }
      }
    }

    &:nth-child(odd) {
      &:before {
        @include breakpoint(m) {
          right: 33.333%;
        }
        @include breakpoint(l) {
          right: 25%;
        }
      }
    }

    &:nth-child(even) {
      &:before {
        @include breakpoint(m) {
          left: calc(33.333% - 1px);
        }
        @include breakpoint(l) {
          left: calc(25% - 1px);
        }
      }

      .stories__item-content {
        @include breakpoint(m) {
          margin-left: 66.666%;
        }
        @include breakpoint(l) {
          margin-left: 75%;
        }
      }
    }

    &--device {
      &-laptop {
        .stories__item-device {
          width: calc((100vw - (#{$cnt-spacing} * 2)) * 1.975);
          left: 50%;
          transform: translateX(-50%);
          margin-bottom: calc((100vw - (#{$cnt-spacing} * 2)) * -0.47);
          @include breakpoint(s) {
            margin-bottom: -180px;
            width: 758px;
          }
          @include breakpoint(m) {
            transform: none;
            margin-bottom: 0;
            width: 792px;
            top: 66px;
          }
          @include breakpoint(l) {
            width: 927px;
            top: 67px;
          }
          @include breakpoint(xxl) {
            width: 1131px;
            top: 72px;
          }

          &-graphic {
            background-image: url(../img/stories/laptop.png);
            width: 100%;
            height: 0;
            padding-top: 59.416%;
          }

          &-screen {
            height: 49.852%;
            left: 26.349%;
            top: 3.423%;
            width: 47.215%;
          }
        }

        &:nth-child(odd) {
          .stories__item-device {
            @include breakpoint(m) {
              left: auto;
              right: -196px;
            }
            @include breakpoint(l) {
              right: -229px;
            }
            @include breakpoint(xxl) {
              right: -280px;
            }
          }
        }

        &:nth-child(even) {
          .stories__item-device {
            @include breakpoint(m) {
              right: auto;
              left: -195px;
            }
            @include breakpoint(l) {
              left: -229px;
            }
            @include breakpoint(xxl) {
              left: -279px;
            }
          }
        }
      }

      &-phone {
        .stories__item-device {
          width: calc((100vw - (#{$cnt-spacing} * 2)) * 0.592);
          left: 50%;
          transform: translateX(-50%);
          margin-bottom: calc((100vw - (#{$cnt-spacing} * 2)) * -0.21);;
          @include breakpoint(s) {
            width: 227px;
            margin-bottom: -80px;
          }
          @include breakpoint(m) {
            margin-bottom: 0;
            transform: none;
            width: 254px;
            top: -14px;
          }
          @include breakpoint(l) {
            width: 288px;
            top: -18px;
          }
          @include breakpoint(xxl) {
            width: 339px;
            top: -18px;
          }

          &-graphic {
            background-image: url(../img/stories/phone.png);
            width: 100%;
            height: 0;
            padding-top: 208.259%;
          }

          &-screen {
            height: 63.173%;
            left: 11.8%;
            top: 9.208%;
            width: 76.405%;
          }
        }

        &:nth-child(odd) {
          .stories__item-device {
            @include breakpoint(m) {
              left: auto;
              right: 108px;
            }
            @include breakpoint(l) {
              right: 96px;
            }
            @include breakpoint(xxl) {
              right: 72px;
            }
          }
        }

        &:nth-child(even) {
          .stories__item-device {
            @include breakpoint(m) {
              right: auto;
              left: 107px;
            }
            @include breakpoint(l) {
              left: 96px;
            }
            @include breakpoint(xxl) {
              left: 68px;
            }
          }
        }
      }
    }
  }

  &__single {
    &-basic {
      @include clear;
      @extend .distance--bottom-3xl;

      &-group {
        @extend .distance--bottom-l;
        @extend .paragraph--size-l;

        h4 {
          @extend .distance--bottom-xs;
          font-weight: 600;
        }
      }

      &-preview {
        position: relative;
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 2.5rem;
        @include breakpoint(s) {
          width: 425px; 
        }
        @include breakpoint(m) {
          left: auto;
          transform: none;
          margin-bottom: 0;
          float: right;
          width: 570px;
          margin-right: -100px;
          top: -5px;
        }
        @include breakpoint(l) {
          width: 800px;
          margin-right: -115px;
          top: -15px;
        }
        @include breakpoint(xxl) {
          width: 971px;
          margin-right: -315px;
          top: -15px;
        }

        &-graphic {
          position: relative;
          width: 100%;
          height: 0;
          padding-top: 88.672%;
          margin-bottom: -16%;
          background-image: url('../img/stories/desktop.png');
          background-size: 100% 100%;
        }

        &-screen {
          background-color: $color-mountain-mist;
          position: absolute;
          left: 9.475%;
          top: 7.782%;
          width: 80.948%;
          height: 57.027%;

          > * {
            @include cover;
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &-client-story {
      @extend .distance--bottom-3xl;

      &-slider {
        position: relative;
        padding: 3rem 0 0 0;
        @include breakpoint(l) {
          padding: 100px 0 0 0;
        }

        &:before {
          content: '';
          background-color: $color-aqua-haze;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 80px;
          width: 100vw;
          z-index: -1;
        }

        &-padding {
          @extend .distance--bottom-xs;
          padding-right: $cnt-spacing;
          @include breakpoint(l) {
            padding-right: 250px;
          }
        }
      }

      .text-slider {
        h4 {
          @extend .title--size-m;
          @extend .distance--bottom-xl;
          line-height: 1;
          font-weight: 700;
        }

        p {
          @extend .paragraph--size-l;
          @extend .distance--bottom-m;
        }

        &__arrows {
          @extend .distance--bottom-l;
          @include flexbox-justify-content(flex-start);
          float: none;
          margin-right: $cnt-spacing;
          margin-top: 2rem;
          @include breakpoint(l) {
            float: right;
            margin-right: -25px;
          }
          @include breakpoint(xxl) {
            margin-right: -50px;
          }

          .button {
            margin: 0;
          }
        }
      }

      &-quote {
        @extend .paragraph--size-l;
        background: $color-white;
        clear: both;
        position: relative;
        padding: 32px $cnt-spacing;
        width: calc(100% + #{$cnt-spacing});
        box-shadow: 0 14px 24px 0 rgba($color-lynch, 0.1);
        margin-top: 2rem;
        @include breakpoint(l) {
          margin-top: 0;
          width: auto;
          right: -150px;
          padding: 64px 150px;
          box-shadow: 0 20px 34px 0 rgba($color-lynch, 0.1);
        }

        p {
          @extend .distance--bottom-xxs;

          &:first-child {
            // font-style: italic;
          }

          &:last-child {
            text-align: right;
          }
        }
      }
    }

    &-list {
      @extend .distance--bottom-3xl;

      .cnt > * {
        @extend .limit--size-l;
      }

      &-question {
        @extend .distance--bottom-l;
        font-size: 20px;
        font-weight: 600;
        padding-left: 64px;
        position: relative;
        @include breakpoint(m) {
          font-size: 24px;
          padding-left: 80px;
        }

        &:before {
          content: '';
          width: 40px;
          height: 1px;
          position: absolute;
          background: currentColor;
          top: 16px;
          left: 0;
          @include breakpoint(m) {
            top: 19px;
          }
        }
      }

      &-list {
        @extend .paragraph--size-l;
        counter-reset: story-challenge;

        li {
          @extend .distance--bottom-l;
          counter-increment: story-challenge;
          position: relative;
          padding-left: 64px;
          @include breakpoint(m) {
            padding-left: 80px;
          }

          &.is-stared {
            font-weight: 700;

            &:after {
              content: '*';
              position: absolute;
              top: 0;
              right: 100%;
              line-height: 2;
              heihgt: 100%;
              margin-right: 8px;
              color: $color-atomic-tangerine;
            }
          }

          &:before {
            content: counters(story-challenge, '.', decimal-leading-zero) '.';
            position: absolute;
            color: $color-mountain-mist;
            left: 0;
            top: 13px;
            margin-top: -15px;
            font-size: 30px;
            font-weight: 400;
            line-height: 30px;
            @include breakpoint(m) {
              top: 14px;
              font-size: 36px;
              line-height: 36px;
              margin-top: -18px;
            }
          }
        }
      }
    }

    &-slideshow {
      @extend .distance--bottom-3xl;
      position: relative;

      .cnt {
        padding-top: 3rem;
        padding-bottom: 3rem;
        position: relative;
        @include breakpoint(l) {
          padding-top: 140px;
          padding-bottom: 140px;
        }
      }

      &-background {
        position: absolute;
        background: $color-aqua-haze;
        left: 25%;
        width: calc(100vw - (100vw - #{$cnt-width-l}) / 2 - 25%);
        top: 0;
        bottom: 0;
        z-index: 1;
        background-size: cover;
      }

      .carousel {
        @extend .distance--bottom-xl;
        box-shadow: 0 15px 70px 0 rgba($color-black, 0.1);
        z-index: 2;
        margin: 0 auto;
        @include breakpoint(m) {
          width: 100%;
        }
        @include breakpoint(l) {
          width: 80%;
        }
        @include breakpoint(xxl) {
          width: 100%;
        }

        &__wrapper {
          width: 100%;
          padding-top: 60%;
          height: 0;
        }

        &__dots {
          position: relative;
          z-index: 2;
        }
      }
    }

    &-results {
      @extend .distance--bottom-3xl;
      
      .cnt > * {
        @extend .limit--size-l;
      }
    }

    &-last {
      position: relative;

      &-background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          transform: skew(0deg, -10deg);
          transform-origin: top right;
          background: $color-shark;
        }
      }

      &-preview {
        position: relative;
        width: 385px;
        margin: 0 auto;
        transform: scale(0.9);
        transform-origin: center;
        @include breakpoint(m) {
          width: 700px;
          transform: none;
          margin-left: auto;
          margin-right: 0;
        }
        @include breakpoint(xxl) {
          width: 978px;
          margin-left: 183px;
        }

        &-graphic {
          position: relative;
          width: 100%;
          height: 0;
          padding-top: 76.074%;
          background-image: url('../img/stories/desktop-3d.png');
          background-size: 100% 100%;
        }

        // matrix3d transformations: https://codepen.io/fta/pen/ifnqH

        &-screen-1 {
          outline: 1px solid transparent;
          background-color: $color-mountain-mist;
          position: absolute;
          top: 0;
          left: 0;
          width: 65.44%;
          height: 53.764%;
          transform-origin: 0px 0px 0px;
          transform: unquote('matrix3d(0.888163, 0.0196515, 0, -0.000244591, -0.129267, 1.04094, 0, -5.69082e-05, 0, 0, 1, 0, 53, 11, 0, 1)');
          @include breakpoint(m) {
            transform: unquote('matrix3d(0.881766, 0.0195913, 0, -0.000147538, -0.120443, 1.04078, 0, -2.56084e-05, 0, 0, 1, 0, 95, 19, 0, 1)');
          }
          @include breakpoint(xxl) {
            transform: unquote('matrix3d(0.878671, 0.0172558, 0, -0.000109981, -0.124307, 1.03293, 0, -2.15083e-05, 0, 0, 1, 0, 133, 28, 0, 1)');
          }

          > * {
            background-size: 100%;
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        &-screen-2 {
          outline: 1px solid transparent;
          background-color: $color-mountain-mist;
          position: absolute;
          top: 0;
          left: 0;
          width: 38.855%;
          height: 42.339%;
          transform-origin: 0px 0px 0px;
          box-shadow: 0 11px 49px 0 rgba($color-black, 0.1);
          transform: unquote('matrix3d(0.955387, 0.0225979, 0, -0.000359236, -0.137681, 1.11253, 0, -4.2396e-05, 0, 0, 1, 0, 220, 60, 0, 1)');
          @include breakpoint(m) {
            transform: unquote('matrix3d(0.979122, 0.0279185, 0, -0.000161591, -0.160968, 1.10169, 0, -6.26766e-05, 0, 0, 1, 0, 401, 110, 0, 1)');
            box-shadow: 0 15px 70px 0 rgba($color-black, 0.1);
          }
          @include breakpoint(xxl) {
            transform: unquote('matrix3d(0.977818, 0.0267639, 0, -0.000119794, -0.137551, 1.11341, 0, -1.42429e-05, 0, 0, 1, 0, 560, 154, 0, 1)');
          }

          > * {
            @include cover;
            display: block;
            width: 100%;
            height: 100%;
            background-position: left center;
          }
        }
      }
    }
  }
}