// Text Slider
.text-slider {
  position: relative;
  perspective: 1000px;

  &__item {
    display: none;
    width: 100%;
    will-change: display, transform, opacity;

    &.is-active {
      display: block;
    }
  }

  &__dots {
    @include flexbox-display;
    @include flexbox-flow(row nowrap);

    li {
      @include flexbox-flex(0 0 auto);
      @include transition(background);
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: rgba($color-white, 0.5);
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:hover {
        background: rgba($color-white, 0.8);
      }

      &.is-active {
        background: $color-atomic-tangerine;
      }
    }
  }

  &__arrows {
    @extend .flexbox--center-row;

    &-status {
      font-size: 17px;
      margin-right: 20px;
      @include breakpoint(m) {
        font-size: 20px;
      }
    }

    .button {
      @include transition(background, color, filter, opacity);
      width: 36px;
      height: 36px;
      font-size: 18px;
      border-radius: 0;
      @include breakpoint(m) {
        width: 50px;
        height: 50px;
        font-size: 26px;
      }

      &.is-disabled {
        filter: grayscale(1);
        background-position: top left;
        cursor: default;
        opacity: 0.5;
      }
    }
  }
}