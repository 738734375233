// Blog
.blog {
  &__item {
    @extend .distance--bottom-2xl;
    position: relative;
    color: $color-shark;
    @include breakpoint(m) {
      padding: 2.5rem 0;
    }
    @include breakpoint(l) {
      padding: 66px 0;
    }

    &:before {
      @include breakpoint(m) {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100vw;
        background: $color-aqua-haze;
        z-index: -1;
      }
    }

    &-content {
      z-index: 2;
      @include breakpoint(m) {
        min-height: 260px;
      }
      @include breakpoint(l) {
        @include flexbox-display;
        @include flexbox-flow(column nowrap);
        @include flexbox-justify-content(space-between);
        min-height: 330px;
      }

      &-top {
        @extend .distance--bottom-s;
      }
    }

    &-category {
      @extend .distance--bottom-xs;
      line-height: 1.5;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
      color: $color-atomic-tangerine;
      @include breakpoint(m) {
        font-size: 15px;
      }
    }

    &-title {
      @extend .distance--bottom-xxs;
      font-size: 22px;
      font-weight: 700;
      line-height: 1.25;
      @include breakpoint(m) {
        font-size: 28px;
      }
      @include breakpoint(l) {
        font-size: 32px;
      }
    }

    &-info {
      @extend .distance--bottom-s;
      color: $color-mountain-mist;
      font-size: 14px;
      line-height: 1.5;
      @include breakpoint(m) {
        font-size: 15px;
      }

      a {
        color: $color-atomic-tangerine;

        &:hover {
          color: $color-persimmon;
        }
      }
    }

    &-intro {
      line-height: 1.5;
      font-size: 14px;
      @include breakpoint(m) {
        font-size: 15px;
      }

      a {
        color: $color-atomic-tangerine;

        &:hover {
          color: $color-persimmon;
        }
      }
    }

    &-link {
      @extend .flexbox--center-row;
      font-size: 16px;
      white-space: nowrap;
      @include breakpoint(l) {
        font-size: 18px;
      }

      .icon {
        @include transition(transform);
        font-size: 11px;
        margin-left: 16px;
        @include breakpoint(l) {
          font-size: 12px;
          margin-left: 20px;
        }
      }

      &:hover {
        .icon {
          transform: translateX(15px);
        }
      }
    }

    &-image {
      background-color: $color-iron;
      display: block;
      width: 100%;
      height: 0;
      padding-top: 67%;
      margin: 0 0 1.5rem;
      overflow: hidden;
      position: relative;
      @include breakpoint(s) {
        margin: 0 0 2rem;
        padding-top: 0;
        width: 385px;
        height: 260px;
      }
      @include breakpoint(m) {
        margin: 0;
        position: absolute;
        top: 50%;
        margin-top: -130px;
        box-shadow: 0 34px 64px 0 rgba($color-lynch, 0.1);
      }
      @include breakpoint(l) {
        box-shadow: 0 40px 75px 0 rgba($color-lynch, 0.1);
        margin-top: -165px;
        width: 50%;
        height: 330px;
      }

      div {
        @include cover;
        @include transition(transform);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &:hover {
        div {
          transform: translateZ(0) scale(1.1);
        }
      }
    }

    &:nth-child(odd) {
      &:before {
        left: calc(25% - 1px);
      }

      .blog__item {
        &-content {
          @include breakpoint(m) {
            padding-left: 50%;
          }
        }

        &-image {
          @include breakpoint(m) {
            right: calc(50% + 2rem);
          }
          @include breakpoint(l) {
            left: -5rem;
          }
        }
      }
    }

    &:nth-child(even) {
      &:before {
        right: 25%;
      }

      .blog__item {
        &-content {
          @include breakpoint(m) {
            padding-right: 50%;
          }
        }

        &-image {
          @include breakpoint(m) {
            left: calc(50% + 2rem);
          }
          @include breakpoint(l) {
            left: calc(50% + 5rem);
          }
        }
      }
    }

    &--style {
      &-previous {
        background: $color-aqua-haze;
        padding: 2.5rem 0 2.5rem;
        @include breakpoint(m) {
          padding: 3.0rem 0 2.5rem;
        }
        @include breakpoint(m) {
          padding: 3.5rem 0 3rem;
        }

        &:before {
          display: none;
        }

        .blog__item {
          &-title {
            line-height: 1.2;
            font-size: 20px;
            @include breakpoint(m) {
              font-size: 24px;
            }
          }

          &-info {
            @extend .distance--bottom-none;

            a {
              color: $color-atomic-tangerine;

              &:hover {
                color: $color-persimmon;
              }
            }
          }

          &-link {
            @include flexbox-justify-content(flex-end);
            margin-bottom: 0;
            margin-top: 24px;
            @include breakpoint(m) {
              margin-top: 0;
            }
          }
        }
      }

      &-open-source {
        .blog__item {
          &-thank-you {
            @extend .distance--bottom-xxs;
            color: $color-mountain-mist;
            line-height: 1.5;
            font-size: 16px;
            @include breakpoint(m) {
              font-size: 18px;
            }
          }

          &-contributors {
            @include flexbox-display;
            @include flexbox-flow(row wrap);
            margin-bottom: -0.5rem;

            a {
              display: block;
              margin-right: 0.5rem;
              margin-bottom: 0.5rem;
              background: $color-white;
              box-shadow: 0 4px 8px 0 rgba($color-black, 0.05);
              border-radius: 5px;
              position: relative;
              @include breakpoint(m) {
                box-shadow: 0 5px 10px 0 rgba($color-black, 0.05);
              }

              img {
                display: block;
                width: 3.25rem;
                height: 3.25rem;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }

  &__container {
    position: relative;
  }

  &__article {
    color: $color-shark;

    &-category {
      @extend .distance--bottom-s;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.5;
      color: $color-atomic-tangerine;
      @include breakpoint(m) {
        font-size: 15px;
      }
    }

    &-title {
      @extend .distance--bottom-l;
      font-size: 36px;
      font-weight: 700;
      line-height: 1.13;
      letter-spacing: -0.0066em;
      @include breakpoint(m) {
        font-size: 50px;
        max-width: calc(100% - 140px - 32px);
      }
      @include breakpoint(l) {
        font-size: 60px;
        max-width: unset;
      }
    }

    &-aside {
      @include flexbox-display;
      @include flexbox-flow(column nowrap);
      @include flexbox-justify-content(center);
      position: relative;
      padding-left: 100px;
      min-height: 80px;
      margin: -20px 0 32px;
      @include breakpoint(m) {
        position: absolute;
        display: block;
        right: 0;
        top: 50px;
        width: 160px;
        padding-left: 0;
        margin: 0;
      }
      @include breakpoint(l) {
        top: 60px;
      }
    }

    &-user {
      margin-bottom: 8px;
      @include breakpoint(m) {
        text-align: right;
        margin-bottom: 24px;
      }

      &-photo {
        @extend .distance--bottom-s;
        background-color: $color-white;
        background-image: url('../img/team/blank-avatar-male.svg');
        width: 80px;
        height: 80px;
        background-size: cover;
        border-radius: $border-radius;
        box-shadow: 0 0.5rem 1rem rgba($color-lynch, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        @include breakpoint(m) {
          width: 160px;
          height: 160px;
          position: static;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 3%;
          -moz-box-shadow: 0 0 40px #bbb;
          -webkit-box-shadow: 0 0 40px #bbb;
          box-shadow: 0 0 40px #bbb
        }
      }

      &-name {
        color: $color-amaranth;
        font-size: 17px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 2px;
        @include breakpoint(m) {
          font-size: 18px;
          margin-bottom: 5px;
        }

        &:hover {
          color: $color-persimmon;
        }

        a {
          color: inherit;
        }
      }

      &-position {
        font-size: 13px;
        line-height: 1.2;
        color: $color-dusty-gray;;
      }
    }

    &-info {
      @include flexbox-display;
      color: $color-dusty-gray;
      font-size: 13px;
      @include breakpoint(m) {
        display: block;
        text-align: right;
        padding-right: 15px;
        border-right: 2px solid $color-alto;
      }

      &-date {
        font-weight: 500;

        &:after {
          content: '\b7';
          margin: 0 5px;
          @include breakpoint(m) {
            content: '';
            display: none;
          }
        }
      }
    }

    &-intro {
      @extend .distance--bottom-xl;
      line-height: 1.44;
      font-size: 19px;
      color: $color-boulder;
      max-width: 620px;
      letter-spacing: -0.0111em;
      font-family: $font-roboto;
      @include breakpoint(m) {
        font-size: 23px;
        max-width: calc(100% - 140px - 32px);
      }
      @include breakpoint(l) {
        font-size: 27px;
        max-width: unset;
      }

      a:not(.button) {
        color: $color-atomic-tangerine;

        &:hover {
          color: $color-persimmon;
        }
      }
    }

    &-image {
      @extend .distance--bottom-l;
      width: 100%;
      background-color: $color-mountain-mist;
      @include breakpoint(s) {
        width: 385px;
      }
      @include breakpoint(m) {
        width: 100%;
      }
      @include breakpoint(l) {
        width: 100%;
      }

      > div {
        @include cover;
        width: 100%;
        height: 0;
        padding-top: 66.6667%;
      }
    }

    &--margin-auto {
      @include breakpoint(m) {
        margin-left: auto;
        margin-right: auto;
        max-width: calc(100% - 150px);
      }
      @include breakpoint(l) {
        max-width: unset;
      }
    }
  }

  &__share {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 0;
    z-index: 95;

    .cnt {
      position: relative;
      height: 0;
    }

    &-button {
      @include transition(transform);
      position: absolute;
      right: $cnt-spacing;
      bottom: 2rem;
      transform: translate3d(0, 0, 0);
      @include breakpoint(m) {
        right: $cnt-spacing-m;
      }
      @include breakpoint(xxl) {
        bottom: $cnt-spacing-m * 2;
      }

      body.enable-scroll-to-top & {
        transform: translate3d(0, -48px, 0);
        @include breakpoint(m) {
          transform: translate3d(0, -60px, 0);
        }
      }
    }
  }

  &__list {
    &-scroll-to {
      position: absolute;
      left: 0;
      height: 0;
      top: -128px - 32px;
      @include breakpoint(m) {
        top: -128px - 48px;
      }
      @include breakpoint(l) {
        top: -138px - 64px;
      }
    }

    &-loader {
      top: 175px;
      @include breakpoint(m) {
        top: 195px;
      }
      @include breakpoint(l) {
        top: 200px;
      }
    }

    &-load-more {
      @include transition(opacity);
      height: 0;
      font-size: 20px;
      color: $color-persimmon;
      text-align: center;
      position: relative;
      opacity: 0;
      top: 5px;
      @include breakpoint(m) {
        top: 35px;
      }
      @include breakpoint(l) {
        top: 44px;
      }

      .icon {
        animation: spinner 1s linear infinite;
      }

      &.is-active {
        opacity: 1;
      }
    }

    &-empty {
      text-align: center;
      font-size: 16px;
      @include breakpoint(m) {
        font-size: 18px;
      }

      .blog__item + & {
        @include breakpoint(m) {
           margin-top: -8px;
        }
        @include breakpoint(l) {
           margin-top: -16px;
        }
      }
    }
  }

  .cnt {
    position: relative;
  }

  &--style {
     &-open-source {
      .blog {
        &__article {
          &-info-shields {
            @include flexbox-display;
            @include flexbox-flow(row wrap);
            margin-bottom: -0.5rem;

            a {
              display: block;
              margin-right: 0.5rem;
              margin-bottom: 0.5rem;

              img {
                display: block;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
}
