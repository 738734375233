// Background Grid
.background-grid {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;

  .cnt,
  .grid,
  .grid div {
    height: 100%;
  }

  .grid {
    > div {
      border-right: 1px solid $color-catskill-white;

      &:first-child {
        border-left: 1px solid $color-catskill-white;
      }
    }
  }
}