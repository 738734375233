// Tooltip
.tooltip {
  display: none;
  @include breakpoint(l) {
    @include transition(opacity);
    background-image: linear-gradient(to bottom right, $color-bittersweet, $color-amaranth);
    border-radius: 1rem;
    color: $color-white;
    display: block;
    font-size: 0.8125rem;
    height: 2rem;
    left: 0;
    line-height: 2rem;
    margin-left: 0;
    margin-top: -2.5rem;
    opacity: 0;
    padding: 0 1rem;
    pointer-events: none;
    position: absolute;
    text-transform: uppercase;
    top: 0;
    z-index: 9999;
  }

  &--show {
    opacity: 1;
  }
}
