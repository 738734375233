.file-upload {
  .icon {
    vertical-align: -0.15em;
  }

  &__list {
    @include transition(height);
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 0px;

    @for $i from 1 through 10 {
      .file-upload[files-uploaded="#{$i}"] & {
        height: #{22 * $i + 14}px;
        @include breakpoint(m) {
          height: #{24 * $i + 16}px;
        }
      }
    }

    &-item {
      @extend .paragraph--size-s;
      @include flexbox-display();
      @include flexbox-flow(row nowrap);
      @include flexbox-align-items(center);
      color: $color-mountain-mist;
      width: 100%;
      overflow: hidden;

      &-name {
        @flexbox-flex(1 0 auto);
        color: $color-mortar;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-remove {
        @include button-reset;
        @include transition(color);

        &:hover {
          color: $color-burnt-sienna;
        }
      }

      &-size {
        @include flexbox-flex(1 0 auto);
        font-size: 0.8em;
        white-space: nowrap;
        text-align: right;
        margin-left: 16px;
      }

      .icon {
        @include flexbox-flex(0 0 auto);

        &--file {
          margin-right: 8px;
        }

        &--trash {
          margin-left: 8px;
        }
      }

      &[ajax-file-template] {
        display: none;
      }
    }
  }

  &__drop-zone {
    @include transition(border-color, background-color);
    cursor: pointer;
    padding: 20px;
    border-radius: $border-radius;
    background-color: $color-aqua-haze;
    border: 1px dashed $color-mountain-mist;
    text-align: center;
    color: $color-mountain-mist;
    line-height: 1.4;
    position: relative;

    input[type="file"] {
      position: absolute;
      overflow: hidden;
      width: 0;
      height: 0;
      opacity: 0;

      & + label {
        position: absolute;
        z-index: 3;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 1;
        display: block;
        cursor: pointer;
        visibility: visible;
        pointer-events: all;
        background-color: rgba($color-white, 0.01);
      }
    }

    &-text {
      @extend .paragraph--size-l;
      margin-bottom: 0.33em;
      color: $color-mortar;
      position: relative;
      z-index: 1;

      .icon {
        margin-right: 4px;
      }
    }

    &-info {
      @extend .paragraph--size-s;
      position: relative;
      z-index: 1;

      &-extensions {
        text-transform: uppercase;
      }
    }

    .form__field.is-error & {
      border-color: $color-burnt-sienna;
      background-color: rgba($color-burnt-sienna, 0.1);
    }

    .form__field &.is-dragover,
    .form__field &:hover {
      border-color: $color-atomic-tangerine;
      background-color: rgba($color-atomic-tangerine, 0.1);
    }
  }
}