// Open Source
.open-source {
  @extend .distance--bottom-3xl;

  &__scroll-to {
    position: absolute;
    left: 0;
    top: -98px - 32px;
    height: 0;
    @include breakpoint(m) {
      top: -98px - 48px;
    }
    @include breakpoint(l) {
      top: -108px - 64px;
    }
  }

  &__list {
    margin-bottom: -2rem;
    @include breakpoint(m) {
      margin-bottom: -3rem;
    }
    @include breakpoint(l) {
      margin-bottom: -64px;
    }

    .grid {
      @include grid-distance(0rem, 2rem);
      margin-bottom: 0;
      @include breakpoint(m) {
        @include grid-distance(1.5rem, 3rem);
        margin-bottom: 0;
      }
      @include breakpoint(l) {
        @include grid-distance(24px, 64px);
        margin-bottom: 0;
      }
    }

    &-wrapper {
      min-height: 386px;
      @include breakpoint(m) {
        min-height: 441px;
      }
      @include breakpoint(l) {
        min-height: 468px;
      }
    }

    &-loader {
      top: 175px;
      @include breakpoint(m) {
        top: 195px;
      }
      @include breakpoint(l) {
        top: 200px;
      }
    }

    &-pagination {
      padding-bottom: 2rem;
      @include breakpoint(m) {
        padding-bottom: 3rem;
      }
      @include breakpoint(l) {
        padding-bottom: 64px;
      }

      &:empty {
        display: none;
      }
    }
  }

  &__item {
    &-wrapper {
      @include transition(box-shadow);
      background: $color-selago;
      border-radius: 10px;
      box-shadow: 0 18px 44px 0 rgba($color-lynch, 0.1);
      max-width: 345px;
      margin: 0 auto;
      @include breakpoint(m) {
        max-width: none;
      }
    }

    &:hover {
      .open-source__item-wrapper {
        @include breakpoint(l) {
          box-shadow: 0 18px 75px 0 rgba($color-lynch, 0.4);
        }
      }
    }

    &-image {
      background-color: $color-iron;
      width: 100%;
      height: 180px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;

      div {
        @include cover;
        width: 100%;
        height: 100%;
      }
    }

    &-content {
      position: relative;
      padding: 20px;
    }

    &-category {
      @extend .distance--bottom-xxs;
      font-size: 14px;
      line-height: 1.2;
      font-weight: 700;
      color: $color-atomic-tangerine;
      text-transform: uppercase;
      @include breakpoint(m) {
        font-size: 15px;
      }
    }

    &-title {
      @extend .distance--bottom-m;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.2;
      @include breakpoint(m) {
        font-size: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &-description {
      @extend .distance--bottom-m;
      font-size: 14px;
      line-height: 1.5;
      @include breakpoint(m) {
        font-size: 15px;
        height: 45px;
        overflow: hidden;
      }
    }

    &-link {
      font-size: 14px;
      @include breakpoint(m) {
        font-size: 15px;
      }

      .icon {
        &:first-child {
          font-size: 120%;
          margin-right: 5px;
        }

        &:last-child {
          @include transition(transform);
          font-size: 11px;
          margin-left: 16px;
          @include breakpoint(l) {
            font-size: 12px;
            margin-left: 20px;
          }
        }
      }

      &:hover {
        .icon {
          &:last-child {
            transform: translateX(15px);
          }
        }
      }
    }

    .share {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
}