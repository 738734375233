// Error
.error {
  @include cover;
  @extend .flexbox--center;
  position: relative;
  color: $color-white;
  background-color: $color-haiti;
  background-image: url('../img/error-page/background.jpg');
  overflow: hidden;
  min-height: 100vh;
  padding-top: 50px;

  .cnt {
    width: 100%;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

    &:before {
      content: '';
      position: absolute;
      width: 770px;
      height: 770px;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: url('../img/error-page/halo.png') no-repeat center;
      background-size: 100% 100%;
      @include breakpoint(s) {
        width: 200%;
        padding-top: 200%;
        height: 0;
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    padding-top: 100%;
    height: 0;
  }

  &__inner {
    @extend .flexbox--center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__center {
    text-align: center;
  }

  &__code {
    @extend .distance--bottom-m;
    font-size: 84px;
    font-weight: 700;
    line-height: 0.75;
    @include breakpoint(s) {
      font-size: 100px;
    }
    @include breakpoint(m) {
      font-size: 120px;
    }
  }

  &__text {
    @extend .distance--bottom-m;
    @extend .paragraph--size-l;
  }
}