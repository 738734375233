// Infinite Scroll
.infinite-scroll {
  &__image {
    background-position: left-center;
    background-repeat: repeat-x;
  }

  &--style {
    &-meeting {
      .infinite-scroll {
        &__image {
          height: 250px;
          width: 5428px;
          background-size: 2714px 250px;
          animation: infinite-scroll-meeting 25s linear infinite; 
        }
      }

      @keyframes infinite-scroll-meeting {
        to {
          transform: translateX(-50%);
        }
      }
    }
  }
}