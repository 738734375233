// Loader
.loader {
  $easing: cubic-bezier(.68, 0, .265, 1);
  $delay: 200ms;
  $duration: 666ms;
  $logo-line-length-1: 1089.58;
  $logo-line-length-2: 1044.827;

  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100vw;
  z-index: 300;
  pointer-events: none;
  transition-property: none;
  transition-duration: $duration;

  &.is-disabled {
    display: none;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transform: translate3d(0, 0, 0);
    background: $color-haiti;
    z-index: 1;

    .loader.is-hiding & {
      transition: opacity #{$duration} $easing;
      transform: translate3d(0, 0, 0);
      opacity: 0;
    }

    .loader.is-loaded & {
      transform: translate3d(0, -100vh, 0);
      opacity: 0;
    }

    .loader.is-loaded.is-showing & {
      transition: opacity #{$duration} $easing;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    .loader.is-loaded.is-hiding & {
      transition: opacity #{$duration} $easing;
      transform: translate3d(0, 0, 0);
      opacity: 0;
    }
  }

  &__wiper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transform: translate3d(0, 0, 0);
    background: $color-selago;
    z-index: 2;

    .loader.is-hiding & {
      transition: transform #{$duration} $easing;
      transform: translate3d(0, -100vh, 0);
    }

    .loader.is-loaded & {
      transform: translate3d(0, 100vh, 0);
    }

    .loader.is-loaded.is-showing & {
      transition: transform #{$duration} $easing;
      transform: translate3d(0, 0, 0);
    }

    .loader.is-loaded.is-hiding & {
      transition: transform #{$duration} $easing;
      transform: translate3d(0, -100vh, 0);
    }
  }

  &__logo {
    position: absolute;
    top: 50vh;
    left: 50vw;
    width: 0;
    height: 0;
    font-size: 7rem;
    z-index: 3;
    transform-origin: center;
    color: $color-burnt-sienna;
    @include breakpoint(m) {
      font-size: 8.5rem;
    }
    @include breakpoint(xxl) {
      font-size: 10rem;
    }

    .icon {
      display: block;
      transform: translateX(-50%) translateY(-50%);
    }

    g {
      @include breakpoint(l) {
        stroke: url('#dos-amigos-loader-gradient');
      }
    }

    &-line {
      &-1 {
        $stroke-length: $logo-line-length-1;
        stroke-dasharray: $stroke-length;
        stroke-dashoffset: decimal-round($stroke-length * 3, 0, ceil);

        body.ie & {
          stroke-dashoffset: decimal-round($stroke-length * 2, 0, ceil);
        }
      }

      &-2 {
        $stroke-length: $logo-line-length-2;
        stroke-dasharray: $stroke-length;
        stroke-dashoffset: decimal-round($stroke-length * 3, 0, ceil);

        body.ie & {
          stroke-dashoffset: decimal-round($stroke-length * 2, 0, ceil);
        }
      }
    }

    .loader.is-showing & {
      opacity: 1;

      body.ie & {
        opacity: 1;
      }

      .loader__logo-line {
        &-1 {
          $stroke-length: $logo-line-length-1;
          transition: stroke-dashoffset #{$duration} $easing;
          stroke-dashoffset: decimal-round($stroke-length * 2, 0, ceil);

          body.ie & {
            transition: none;
            stroke-dashoffset: decimal-round($stroke-length * 2, 0, ceil);
          }
        }

        &-2 {
          $stroke-length: $logo-line-length-2;
          transition: stroke-dashoffset #{$duration} $easing;
          stroke-dashoffset: decimal-round($stroke-length * 2, 0, ceil);

          body.ie & {
            transition: none;
            stroke-dashoffset: decimal-round($stroke-length * 2, 0, ceil);
          }
        }
      } 
    }

    .loader.is-hiding & {
      body.ie & {
        opacity: 0;
      }

      .loader__logo-line {
        &-1 {
          $stroke-length: $logo-line-length-1;
          transition: stroke-dashoffset #{$duration} $easing;
          stroke-dashoffset: decimal-round($stroke-length * 1, 0, ceil);

          body.ie & {
            transition: none;
            stroke-dashoffset: decimal-round($stroke-length * 2, 0, ceil);
          }
        }

        &-2 {
          $stroke-length: $logo-line-length-2;
          transition: stroke-dashoffset #{$duration} $easing;
          stroke-dashoffset: decimal-round($stroke-length * 1, 0, ceil);

          body.ie & {
            transition: none;
            stroke-dashoffset: decimal-round($stroke-length * 2, 0, ceil);
          }
        }
      } 
    }

    body.ie & {
      transition: opacity #{$duration} $easing;
      opacity: 0;
    }
  }
}

.pjax-container {
  &:nth-child(2) {
    overflow: hidden;
    height: 0;
    visibility: hidden;
  }
}