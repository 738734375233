// Title
.title {
  line-height: 1;
  font-weight: 700;

  &--size {
    &-s {
      font-size: 22px;
      @include breakpoint(m) {
        font-size: 25px;
      }
    }

    &-m {
      font-size: 28px;
      @include breakpoint(m) {
        font-size: 32px;
      }
    }

    &-xl {
      font-size: 44px;
      @include breakpoint(m) {
        font-size: 52px;
      }
    }
  }

  &--weight {
    &-regular {
      font-weight: 400;
    }

    &-bold {
      font-weight: 700;
    }
  }

  &--transform {
    &-uppercase {
      text-transform: uppercase;
    }
  }
}