// Article
.article {
  line-height: 1.5;
  font-size: 16px;
  @include breakpoint(m) {
    font-size: 18px;
  }

  > * {
    &:not(:last-child) {
      margin-bottom: 1.2em;
    }
  }

  img, svg {
    max-width: 100%;
    display: block;
    height: auto;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.25;

    &:not(:first-child) {
      margin-top: 1.5em;
    }
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.375em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1.125em;
  }

  h5 {
    font-size: 1em;
  }

  h6 {
    font-size: 0.875em;
  }

  ul {
    li {
      @extend .distance--bottom-xxs;
      padding-left: 1.5rem;
      position: relative;

      &:before {
        position: absolute;
        left: 0;
        top: 0;
        content: '\b7'
      }
    }
  }

  a {
    color: $color-atomic-tangerine;

    &:hover {
      color: $color-persimmon;
    }
  }

  &--style {
    &-blog {
      @include clear;
      font-family: $font-roboto;
      font-weight: 300;
      font-size: 17px;
      color: $color-scorpion;
      margin: 0 auto;
      line-height: 1.75;
      @include breakpoint(m) {
        font-size: 19px;
      }

      h1, h2, h3, h4, h5, h6 {
        font-weight: 700;
        line-height: 1.25;
        color: $color-shark;
        font-family: $font-montserrat;

        &:not(:first-child) {
          margin-top: 2.5em;
        }
      }

      ul, ol {
        font-weight: 400;
      }

      blockquote {
        color: $color-tundora;
        line-height: 1.38;
        border-right: 4px solid $color-persimmon;
        font-size: 22px;
        padding-right: 26px;
        @include breakpoint(m) {
          text-align: right;
          float: left;
          font-size: 30px;
          width: 260px;
          padding-right: 32px;
          margin-right: 32px;
          margin-left: -75px;
        }
        @include breakpoint(l) {
          font-size: 36px;
          width: 300px;
          padding-right: 40px;
          margin-right: 60px;
          margin-left: -120px;
        }

        b, strong {
          font-weight: 500;
        }
      }

      p {
        float: none;

        &:not(:last-child) {
          margin-bottom: 2em;
        }
      }
    }
  }
}