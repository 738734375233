// Share
.share {
  @include transition(height, background, box-shadow);
  position: relative;
  overflow: hidden;

  &__trigger {
    @include button-reset;
    @include transition(color);
    @extend .flexbox--center;
    width: 100%;

    &:hover {
      color: $color-amaranth;
    }
  }

  &.is-active {
    background: $color-white;

    .share__trigger {
      color: $color-amaranth;
    }
  }

  &__menu {
    a {
      @extend .flexbox--center;
      color: $color-mountain-mist;

      &:hover {
        color: $color-persimmon;
      }
    }
  }
  
  &--size {
    &-large {
      width: 38px;
      height: 38px;
      border-radius: 19px;
      box-shadow: 0 14px 30px 0 rgba($color-black, 0.1);
      @include breakpoint(m) {
        width: 44px;
        height: 44px;
        border-radius: 22px;
      }

      &.is-active {
        height: 235px;
        @include breakpoint(m) {
          height: 245px;
        }
      }

      .share {
        &__trigger {
          height: 38px;
          font-size: 20px;
          background: $color-white;
          @include breakpoint(m) {
            height: 44px;
            font-size: 23px;
          }

          .icon {
            transform: translateX(-4%)
          }
        }

        &__menu {
          padding-bottom: 11px;
          background: $color-white;

          a {
            height: 38px;
            font-size: 18px;
            @include breakpoint(m) {
              font-size: 20px;
            }
          }
        }
      }
    }

    &-small {
      width: 34px;
      height: 34px;
      border-radius: 17px;

      &.is-active {
        height: 192px;
        box-shadow: 0 14px 30px 0 rgba($color-black, 0.1);
      }

      .share {
        &__trigger {
          height: 34px;
          font-size: 18px;
        }

        &__menu {
          padding-bottom: 8px;

          a {
            height: 30px;
            font-size: 15px;
          }
        }
      }
    }
  }
}