// SVG Icon
.icon {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  width: auto;
  overflow: visible;
  vertical-align: -.2em;
  preserveAspectRatio: none;

  &--dos-gradient {
    display: block;
    width: 0;
    height: 0;
    visibility: hidden;
  }

  &--width {
    $i: 1;
    @while $i <= 1000 {
      &-#{$i} {
        width: #{$i / 100}em;
      }
      $i: $i + 1;
    }
  }
}