.alert {
  @include transition(opacity, visibility);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 250;

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
  }

  &__overlay {
    position: absolute;
    background: rgba($color-shark, 0.5);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
  }
  
  &__window {
    z-index: 2;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    background: $color-selago;
    padding: 30px 50px;
    border-radius: $border-radius;
    width: calc(100vw - #{$cnt-spacing * 2});
    max-width: 400px;
    box-shadow: 0 15px 36px 0 rgba($color-black ,0.2);
    padding: 32px;
    @include breakpoint(m) {
      box-shadow: 0 18px 44px 0 rgba($color-black ,0.2);
      width: calc(100vw - #{$cnt-spacing-m * 2});
      padding: 36px;
    }
    @include breakpoint(l) {
      box-shadow: 0 18px 44px 0 rgba($color-black ,0.2);
      padding: 44px;
    }
  }

  &__icon {
    @include flexbox-flex(0 0 auto);
    margin-right: 16px;
    font-size: 48px;
    display: block;
    @include breakpoint(m) {
      margin-right: 32px;
    }
  }

  &__wrapper {
    @extend .flexbox--center-row;
  }

  &__content {
    @extend .paragraph--size-m;
    text-align: left;
  }

  &__close {
    @include button-reset;
    display: none;
    color: $color-mortar;
    font-size: 20px;
    position: absolute;
    right: 12px;
    top: 12px;
  }
  
  &.is-active {
    opacity: 1;
    visibility: visible;
  }
}