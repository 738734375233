// Margin
.margin {
  $distance: (
    'xxxs' : ( 0.125rem ),
    'xxs'  : ( 0.25rem  ),
    'xs'   : ( 0.5rem   ),
    's'    : ( 0.625rem ),
    'm'    : ( 1rem     ),
    'l'    : ( 1.25rem  ),
    'xl'   : ( 1.5rem   ),
    'xxl'  : ( 1.75rem  ),
    '3xl' : ( 2rem     )
  );
  @each $key, $value in $distance {
    &--bottom-#{$key} {
      margin-bottom: $value;
    }
    &--left-#{$key} {
      margin-left: $value;
    }
    &--right-#{$key} {
      margin-right: $value;
    }
    &--top-#{$key} {
      margin-top: $value;
    }
  }
}