// Footer
.footer {
  background: $color-shark;
  color: $color-white;
  font-size: 13px;
  padding: 50px 0;
  position: relative;
  z-index: 10;
  @include breakpoint(m) {
    padding: 62px 0;
  }
  @include breakpoint(l) {
    padding: 72px 0;
  }

  &__columns {
    @include breakpoint(m) {
      @include flexbox-display;
      @include flexbox-flow(row wrap);
    }
    @include breakpoint(l) {
      @include flexbox-flow(row nowrap);
    }
    @extend .distance--bottom-l;
  }

  &__column {
    max-width: unset;

    &:nth-child(1) {
      width: 100%;
      @include breakpoint(m) {
        width: calc(50% - 20px);
        margin-bottom: 20px;
      }
      @include breakpoint(l) {
        width: 40%;
        margin-bottom: 0;
      }
    }

    &:nth-child(2) {
      width: 100%;
      @include breakpoint(m) {
        width: calc(50% - 20px);
        margin-left: auto;
      }
      @include breakpoint(l) {
        width: 40%;
        margin: 0 auto;
      }
    }

    &:nth-child(3) {
      @include breakpoint(m) {
        width: 100%;
        max-width: 100%;
      }
      @include breakpoint(l) {
        width: 11%;
        max-width: none;
      }
    }

    a {
      display: block;
    }
  }

  &__title {
    @extend .distance--bottom-m;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    color: $color-dolphin;
    line-height: 60px;
    border-bottom: 1px solid $color-dolphin;
    white-space: nowrap;

    a {
      font-size: 32px;
      @include breakpoint(l) {
        font-size: 35px;
      }
    }
  }

  &__row {
    @extend .distance--bottom-xs;

    &--style {
      &-address {
        .grid {
          @include flexbox-flow(row nowrap);

          > div {
            &:first-child {
              @include flexbox-flex(0 0 auto);
              max-width: 120px;
              min-width: 120px;
              padding-right: 20px;
              @include breakpoint(s) {
                max-width: 140px;
                min-width: 140px;
                padding-right: 32px;
              }

              a {
                white-space: nowrap;
              }
            }

            &:last-child {
              @include flexbox-flex(1 0 auto);
              max-width: calc(100% - 120px);
              @include breakpoint(s) {
                max-width: calc(100% - 140px);
              }

              a {
                @include breakpoint(s) {
                  //max-width: 260px;
                }
                @include breakpoint(m) {
                  max-width: none;
                }
              }
            }
          }
        }
      }
    }
  }

  &__sitemap {
    a {
      @extend .distance--bottom-xs;
    }
  }

  &__social {
    ul {
      @include breakpoint(l) {
        @include flexbox-display;
        @include flexbox-flow(row wrap)
      }
    }

    li {
      @extend .distance--bottom-xs;
      display: inline-block;
      vertical-align: top;
      margin-right: 1rem;
      @include breakpoint(l) {
        @include flexbox-flex(0 0 50%);
        display: block;
        margin-right: 0;
      }
    }

    a {
      font-size: 16px;
    }
  }

  &__copyright {
    color: $color-dolphin;
    text-align: left;
  }
}