// Form
.form {
  max-width: 100%;

  &__label {
    @extend .distance--bottom-xxs;
    color: $color-mountain-mist;
    display: block;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
  }

  &__caption,
  &__note {
    color: $color-mountain-mist;
    font-size: 12px;
    line-height: 1;
  }

  &__note {
    @include transition(opacity, margin-bottom);
    height: 20px;
    padding-top: 8px;
    opacity: 0;
    margin-bottom: -20px;
  }

  &__container {
    position: relative;
  }

  &__field {
    position: relative;

    &.is-active {
      .field {
        border-color: $color-haiti;
      }
    }

    &.is-error {
      .form {
        &__label {
          color: $color-burnt-sienna;
        }

        &__note {
          color: $color-burnt-sienna;
          opacity: 1;
          margin-bottom: 0;
        }
      }

      .field {
        color: $color-burnt-sienna;
        border-color: $color-burnt-sienna;
      }
    }

    &.is-success {
      .form__label {
        color: $color-mountain-meadow;
      }

      .field {
        border-color: $color-mountain-meadow;
      }
    }
  }
}
