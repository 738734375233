// Flexbox
.flexbox {
  &--center {
    @include flexbox-display;
    @include flexbox-align-items(center);
    @include flexbox-justify-content(center);
  }

  &--center-row {
    @include flexbox-display;
    @include flexbox-flow(row nowrap);
    @include flexbox-align-items(center);
  }
}