// Content Switch
.content-switch {
  overflow: hidden;
  position: relative;
  will-change: height;

  &__page {
    display: none;
    will-change: opacity;

    &.is-active {
      display: block;
    }
  }
}