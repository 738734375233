// Color
.color {
  &--black {
    color: $color-black;
  }

  &--white {
    color: $color-white;
  }

  &--atomic-tangerine {
    color: $color-atomic-tangerine;
  }

  &--persimmon {
    color: $color-persimmon;
  }

  &--dolphin {
    color: $color-dolphin;
  }
}