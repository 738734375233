// Cnt
.cnt {
  margin: 0 auto;
  padding: 0 $cnt-spacing;
  @include breakpoint(s) {
    max-width: $cnt-width-s;
  }
  @include breakpoint(m) {
    max-width: $cnt-width-m;
    padding: 0 $cnt-spacing-m;
  }
  @include breakpoint(l) {
    max-width: $cnt-width-l;
  }
}