// Button
.button {
  @include button-transition;
  @include inline-block;
  -moz-osx-font-smoothing: $font-smoothing-moz;
  -webkit-font-smoothing: $font-smoothing-webkit;
  text-align: center;
  position: relative;
  white-space: nowrap;
  z-index: 1;

  &--reset {
    @include button-reset;
  }

  &--link {
    @include button-reset;
    color: $link-color;
    text-decoration: $link-decoration;

    &:hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }

  &--color {
    &-gradient {
      @include transition(background, color);
      background-image: linear-gradient(to bottom right, $color-bittersweet, $color-amaranth, $color-bittersweet);
      background-size: 200% 200%;
      background-position: top left;

      &:hover {
        background-position: bottom right;
      }
    }
  }

  &--size {
    &-s {
      height: 38px;
      line-height: 38px;
      padding: 0 1rem;
      font-size: 14px;
      @include breakpoint(m) {
        height: 40px;
        line-height: 40px;
        padding: 0 1.25rem;
        font-size: 15px;
      }
    }

    &-m {
      height: 48px;
      line-height: 48px;
      padding: 0 1.7rem;
      font-size: 14px;
      @include breakpoint(m) {
        height: 56px;
        line-height: 56px;
        padding: 0 2rem;
        font-size: 15px;
      }
    }
  }

  &--style {
    &-default,
    &-outline {
      display: block;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      border: 0 solid transparent;
      border-radius: 100rem;
      font-weight: 700;
      text-transform: uppercase;
      @include breakpoint(s) {
        @include inline-block;
        width: auto;
        margin-left: unset;
        margin-right: unset;
      }
    }

    &-default {
      color: $color-white;

      &:hover {
        color: $color-white;
      }
    }

    &-outline {
      color: $color-persimmon;

      &:before {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        bottom: 2px;
        right: 2px;
        background: $document-background;
        border-radius: 100rem;
        z-index: -1;
      }

      &:hover {
        color: $color-amaranth;
      }
    }

    &-shadow {
      &.button{
        &--size {
          &-s {
            box-shadow: 0 9px 18px 0 rgba($color-persimmon, 0.35);
            @include breakpoint(m) {
              box-shadow: 0 11px 22px 0 rgba($color-persimmon, 0.35);
            }
          }

          &-m {
            box-shadow: 0 14px 28px 0 rgba($color-persimmon, 0.35);
            @include breakpoint(m) {
              box-shadow: 0 16px 32px 0 rgba($color-persimmon, 0.35);
            }
          }
        }
      }
    }

    &-icon {
      .icon {
        font-size: 140%;
        margin-right: 5px;
      }

      &.button{
        &--size {
          &-m {
            padding: 0 1rem;
            @include breakpoint(m) {
              padding: 0 2rem;
            }
          }
        }
      }
    }

    &-shadow-dark {
      &.button{
        &--size {
          &-s {
            box-shadow: 0 9px 18px 0 rgba($color-black, 0.2);
            @include breakpoint(m) {
              box-shadow: 0 11px 22px 0 rgba($color-black, 0.2);
            }
          }

          &-m {
            box-shadow: 0 14px 28px 0 rgba($color-black, 0.2);
            @include breakpoint(m) {
              box-shadow: 0 16px 32px 0 rgba($color-black, 0.2);
            }
          }
        }
      }
    }
  }

  &--contain {
    &-icon {
      @include flexbox-display;
      @include flexbox-flow(row nowrap);
      @include flexbox-align-items(center);

      svg:first-child, 
      img:first-child,
      .icon:first-child {
        margin-right: 1rem;
      }
    }
  }
}