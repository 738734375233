// Divider
.divider {
  @include gradient-horizontal($color-bittersweet, $color-amaranth);
  @extend .distance--bottom-s;
  width: 40px;
  height: 2px;

  &--color {
    &-haiti {
      background: $color-haiti;
    }

    &-atomic-tangerine {
      background: $color-atomic-tangerine;
    }

    &-shark {
      background: $color-shark;
    }

    &-white {
      background: $color-white;
    }
  }

  &--size {
    &-small {
      margin-bottom: 1rem !important;
      width: 20px;
      height: 1px;
    }
  }
}