// Section
.section {
  @include cover;

  &__feature {
    max-width: 280px;
    @include breakpoint(s) {
      max-width: none;
    }

    .grid {
      @include grid-distance(1rem);
      @include breakpoint(s) {
        @include grid-distance(0);
      }
    }

    &-item {
      width: 110px;
      text-align: center;
    }

    &-icon {
      @extend .distance--bottom-s;
      background-size: 100% 100%;
      width: 87px;
      height: 56px;
      margin: 0 auto;
      @include breakpoint(m) {
        width: 96px;
        height: 62px;
      }
    }

    &-title {
      font-size: 13px;
      font-weight: 700;
      line-height: 20px;
      color: $color-shark;
      text-transform: uppercase;
      @include breakpoint(m) {
        font-size: 14px;
      }
    }
  }

  &__container {
    position: relative;
  }

  &__buttons {
    @include breakpoint(s) {
      @include flexbox-display;
      @include flexbox-flow(row nowrap);
      @include flexbox-align-items(flex-start);
    }

    .button {
      margin-left: 0;

      &:not(:last-child) {
        margin-bottom: 1rem;
        @include breakpoint(s) {
          margin-right: 16px;
          margin-bottom: 0;
        }
        @include breakpoint(m) {
          margin-right: 24px;
        }
      }

      &.button--style-shadow {
        z-index: 2;
      }
    }
  }

  &__customers {
    &-item {
      @include transition(filter, opacity);
      display: block;
      opacity: 0.33;
      filter: grayscale(100%) brightness(0%);

      &:hover {
        filter: none;
        opacity: 1;
      }

      &-logo {
        @include contain;
        display: block;
        width: 100%;
        height: 0;
        padding-top: 55.2%;
        @include breakpoint(s) {
          width: 175px;
          height: 97px;
          padding-top: 0;
        }
        @include breakpoint(m) {
          width: 100%;
          height: 0;
          padding-top: 55.2%;
        }
      }
    }

    .grid {
      @include grid-distance(2rem, 1.5rem);
      @include breakpoint(m) {
        @include grid-distance(5.5rem, 3rem);
      }
      @include breakpoint(l) {
        @include grid-distance(5.5rem, 3.5rem);
      }
    }
  }

  &--style {
    &-design-pages {
      .section {
        &__cnt {
          max-width: 1280px;
          padding: 0;
        }

        &__image {
          display: block;
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }
    }

    &-design-services {
      background: $color-aqua-haze;
      padding: 64px 0;

      .title {
        margin-bottom: 32px;
      }

      .section {
        &__grid {
          > div {
            &:last-child {
              margin-top: 11.2px;
              @include breakpoint(s) {
                margin-top: 0;
              }
            }
          }
        }

        &__list {
          line-height: 1.4;
          font-size: 16px;
          @include breakpoint(m) {
            font-size: 18px;
            padding-left: 25%;
          }

          li {
            @extend .distance--bottom-xs;
          }
        }
      }
    }

    &-success {
      counter-reset: container;

      .cnt {
        @extend .distance--bottom-2xl;
      }

      .title {
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding-left: 64px;
        @include breakpoint(m) {
          padding-left: 80px;
        }

        &:before {
          counter-increment: container;
          content: counter(container);
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          font-size: 85px;
          font-weight: 700;
          line-height: 0.8;
          color: $color-aqua-haze;
          z-index: -1;
          top: 0;
          left: 0;
          vertical-align: top;
          transform: translateY(-50%);
          width: 64px;
          text-align: center;
          @include breakpoint(m) {
            font-size: 100px;
          }
        }
      }

      .section {
        &__container {
          @extend .distance--bottom-3xl;
          @include breakpoint(m) {
            @include flexbox-display;
            @include flexbox-align-items(center);
            min-height: 250px;
          }
        }

        &__scroll-to {
          position: absolute;
          top: -8rem;
          left: 0;
          height: 0;
          visibility: hidden;
        }

        &__limit {
          max-width: 100%;
          @include breakpoint(m) {
            max-width: 55%;
          }
          @include breakpoint(l) {
            max-width: 60%;
          }
        }

        &__image {
          margin: 1.5rem auto -1rem;
          display: block;
          width: 260px;
          height: auto;
          @include breakpoint(m) {
            position: absolute;
            margin: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          @include breakpoint(l) {
            width: 300px;
          }
        }

        &__container {
          &:nth-child(even) {
            .section {
              &__image {
                @include breakpoint(m) {
                  right: auto;
                  left: 0;
                }
              }

              &__limit {
                margin-left: auto;
              }
            }
          }
        }

        &__text-background {
          display: none;
          @include breakpoint(m) {
            display: block;
            text-align: right;
            font-size: 160px;
            font-weight: 700;
            line-height: 0.77;
            color: $color-aqua-haze;
          }
          @include breakpoint(l) {
            font-size: 200px;
          }
        }
      }
    }

    &-success-menu {
      background: $color-aqua-haze;
      padding: 64px 0;

      .grid {
        > div:first-child {
          margin-bottom: 32px;
          @include breakpoint(m) {
            margin-bottom: 0;
            padding-right: 40px;
          }
        }
      }

      .section {
        &__menu-list {
          @extend .paragraph--size-l;
          li {
            @extend .distance--bottom-s;
            padding-left: 1.5rem;
            position: relative;

            &:before {
              position: absolute;
              left: 0;
              top: 0;
              content: '\b7'
            }

            a {

            }
          }
        }
      }
    }

    &-dream-or-idea {
      .limit--size-m {
        @include breakpoint(m) {
          max-width: 55%;
        }
        @include breakpoint(l) {
          max-width: 60%;
        }
      }

      .section {
        &__image {
          margin: 1.5rem auto -1rem;
          display: block;
          width: 260px;
          height: 286px;
          @include breakpoint(m) {
            position: absolute;
            right: 0;
            top: 50%;
            margin: -143px 0 0;
          }
          @include breakpoint(l) {
            position: absolute;
            right: 0;
            top: 50%;
            width: 300px;
            height: 330px;
            margin: -165px 0 0;
          }
        }
      }
    }

    &-timeline {
      .section {
        &__timeline {
          position: relative;
          @extend .distance--bottom-l;

          &-date {
            @include flexbox-display;
            @include flexbox-flow(row nowrap);
            @include flexbox-align-items(center);
            @extend .distance--bottom-l;
            position: relative;
            height: 32px;
            line-height: 32px;
          }

          &-year {
            font-size: 15px;
            position: relative;
            margin-right: 80px;

            &:after {
              content: '';
              position: absolute;
              top: calc(50% - 1px);
              left: calc(100% + 15px);
              height: 1px;
              width: 50px;
              background: $color-haiti;
            }
          }

          &-icon {
            font-size: 24px;
            margin-right: 20px;
          }

          &-ceo {
            &-item {
              @extend .distance--bottom-l;
              position: relative;

              .paragraph--size-l {
                p:not(:first-child) {
                  margin-top: 0.5em;
                }
              }

              .grid {
                @include flexbox-align-items(center);
              }

              &:nth-child(odd) {
                .grid {
                  @include flexbox-flow(row wrap);
                }

                .section__timeline-ceo-image {
                  position: relative;
                  @include breakpoint(m) {
                    left: calc(100% - 412px - 2rem);
                  }
                  @include breakpoint(l) {
                    left: calc(100% - 550px - 5rem);
                  }
                }
              }

              &:nth-child(even) {
                .grid {
                  @include flexbox-flow(row-reverse wrap);
                }

                .section__timeline-ceo-image {
                  position: relative;
                  @include breakpoint(m) {
                    left: 2rem;
                  }
                  @include breakpoint(l) {
                    left: 5rem;
                  }
                }
              }
            }

            &-image {
              @include cover;
              width: 100%;
              height: 0;
              padding-top: 64%;
              margin: 0 0 1.5rem;
              @include breakpoint(s) {
                margin: 0 auto 2rem;
                padding-top: 0;
                width: 385px;
                height: 245px;
              }
              @include breakpoint(m) {
                margin: 0;
                width: 412px;
                height: 262px;
              }
              @include breakpoint(l) {
                width: 550px;
                height: 350px;
              }
            }

            &-social {
              font-size: 20px;

              a {
                color: $color-mortar;

                &:not(:last-child) {
                  margin-right: 8px;
                }

                &:hover {
                  color: $color-atomic-tangerine;
                }
              }
            }
          }

          &-developers {
            .grid {
              @include grid-distance(20px, 1rem);
              @include breakpoint(s) {
                @include grid-distance(0, 1rem);
              }
              @include breakpoint(m) {
                @include grid-distance(20px, 0);
              }
              @include breakpoint(l) {
                @include grid-distance(20px, 0);
              }
            }

            &-image {
              @include cover;
              @extend .distance--bottom-xs;
              width: 100%;
              height: 0;
              padding-top: 75%;
              @include breakpoint(s) {
                width: 182px;
                height: 136px;
                padding-top: 0;
                margin: 0 auto;
              }
              @include breakpoint(m) {
                width: 100%;
                height: 0;
                padding-top: 75%;
                margin: 0;
              }
            }

            .paragraph {
              color: $color-mortar;
              font-weight: 600;
              @include breakpoint(s) {
                text-align: center;
              }
              @include breakpoint(m) {
                text-align: left;
              }
            }
          }
        }
      }
    }

    &-meet-our-team {
      .button {
        margin-top: 1.5rem;
        @include breakpoint(l) {
          position: absolute;
          margin-top: 0;
          right: 0;
          top: 0;
        }
      }
    }

    &-home-text {
      padding: 5.25rem 0;
      @include breakpoint(m) {
        padding: 6rem 0;
      }
      @include breakpoint(l) {
        padding: 7.5rem 0;
      }

      .cnt {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          background-image: url('../img/section/background-home.png');
          background-size: contain;
          background-repeat: no-repeat;
          z-index: -1;
          right: 0;
          width: 800px;
          height: 800px;
          top: 50%;
          margin-top: -400px;
          margin-right: -270px;
          transform: scale(0.85);
          @include breakpoint(m) {
            transform: scale(1);
          }
        }
      }
    }

    &-home-services {
      overflow: hidden;

      .section {
        &__services {
          margin: 0 #{-$cnt-spacing};
          @include breakpoint(m) {
            margin: 0 #{-$cnt-spacing-m};
          }

          > div {
            &:last-child {
              background-color: $color-valhalla;
              color: $color-white;

              .section__services-title {
                color: $color-white;

                span {
                  font-size: 1.3em;
                  font-weight: 700;
                }
              }
            }
          }

          @include breakpoint(l) {
            & > div {
              border-left: 1px solid $color-alto;
              border-bottom: 1px solid $color-alto;

              &:nth-child(3n + 3) {
                border-right: 1px solid $color-alto;
              }
            }
          }

          &-item {
            padding: $cnt-spacing * 1.5 $cnt-spacing $cnt-spacing * 1.5 $cnt-spacing;
            @include breakpoint(m) {
              padding: $cnt-spacing-m * 1.5 $cnt-spacing-m $cnt-spacing-m;
            }
          }

          &-title {
            @extend .distance--bottom-m;
            font-size: 27px;
            line-height: 1.25;
            color: $color-tundora;
            font-weight: 500;
            letter-spacing: -0.015em;
            @include breakpoint(m) {
              font-size: 32px;
            }
          }
        }
      }
    }

    &-home-blog {
      padding: 5.25rem 0;
      @include breakpoint(m) {
        padding: 6rem 0;
      }
      @include breakpoint(l) {
        padding: 7.5rem 0;
      }

      .blog {
        &__list {
          &--tiny {
            @include breakpoint(l) {
              @include flexbox-display;
              @include flexbox-flow(row nowrap);
              @include flexbox-justify-content(space-between);
            }
          }
        }

        &__item {
          @extend .distance--bottom-xl;

          &--tiny {
            padding: 0;
            @include breakpoint(m) {
              padding: $cnt-spacing-m;
            }
            @include breakpoint(l) {
              width: calc(33.333% - #{$cnt-spacing-m * 0.666});
              margin-bottom: 0 !important;
            }

            &:before {
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: auto;
              height: auto;
            }

            .blog__item {
              &-image {
                display: none;
              }

              &-content {
                padding: 0 !important;
                min-height: unset;
              }

              &-title {
                @extend .title--size-s;
              }

              &-intro {
                height: 14px * 3 * 1.5;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}
