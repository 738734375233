// Reset
@mixin button-reset {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border-radius: 0;
  border-width: 0;
}

// Transition
@mixin button-transition {
  @include transition(text-shadow, color, background-color, border-color, box-shadow, opacity, visibility);
}