// Hero
.header {
  @include flexbox-display;
  @include flexbox-flow(row nowrap);
  @include flexbox-align-items(center);
  color: $color-white;
  background-color: $color-haiti;
  padding-top: 50px;
  position: relative;

  &__background {
    @include cover;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &__content {
    @include flexbox-display;
    @include flexbox-flow(row nowrap);
    @include flexbox-justify-content(space-between);
    @include flexbox-align-items(center);
    position: relative;
  }

  .cnt {
    width: 100%;
  }

  &--style {
    &-story {
      @extend .distance--bottom-4xl;
      height: 540px;
      @include breakpoint(m) {
        height: 600px;
      }
      @include breakpoint(l) {
        height: 650px;
      }
      @include breakpoint(xxl) {
        height: 800px;
      }

      .header {
        &__title {
          font-weight: 400;
          font-size: 42px * 0.7;
          line-height: 1;
          @include breakpoint(m) {
            font-size: 42px * 0.85;
          }
          @include breakpoint(l) {
            font-size: 42px;
          }

          span {
            font-weight: 700;
            margin-bottom: 0.2em;
            display: block;
            font-size: 1.5em;
          }
        }

        &__background {
          background-image: url('../img/stories/background-header.jpg');
        }
      }
    }

    &-hero {
      @extend .distance--bottom-2xl;
      height: 540px;
      @include breakpoint(m) {
        height: 600px;
      }
      @include breakpoint(l) {
        height: 650px;
      }
      @include breakpoint(xxl) {
        height: 800px;
      }

      .header {
        &__background {
          overflow: hidden;
          background-image: url('../video/header/background-home.jpg');

          video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }

        &__slider {
          @include flexbox-flex(1 0 auto);
          text-transform: uppercase;
          font-weight: 400;
          font-size: 10.5vw;
          line-height: 1.20;
          @include breakpoint(s) {
            font-size: 42px;
          }
          @include breakpoint(m) {
            font-size: 50px;
          }
          @include breakpoint(l) {
            font-size: 60px;
          }

          br {
            display: none;
            @include breakpoint(m) {
              display: inline;
            }
          }

          &-wrapper {
            position: relative;
            white-space: nowrap;
            vertical-align: text-bottom;
            display: block;
            @include breakpoint(m) {
              display: inline-block;
            }

            &:after {
              content: '|';
              position: relative;
              font-weight: 400;
              left: -0.2em;
              top: -0.05em;
              color: $color-atomic-tangerine;
              animation: cursor-blink 1s infinite;

              @keyframes cursor-blink {
                0% {
                  opacity: 0;
                }
                40% {
                  color: $color-atomic-tangerine;
                  opacity: 0;
                }
                50% {
                  color: $color-atomic-tangerine;
                  opacity: 1;
                }
                90% {
                  opacity: 1;
                }
                100% {
                  color: $color-amaranth;
                  opacity: 0;
                }
              }
            }
          }

          &-article {
            white-space: pre-wrap;
          }

          &-word {
            font-weight: 700;
          }
        }

        &__social {
          display: none;
          @include breakpoint(l) {
            display: block;
            text-align: center;
          }

          li {
            @extend .distance--bottom-xs;
            font-size: 18px;
          }

          a {
            display: block;
            color: $color-white;

            &:hover {
              color: $color-atomic-tangerine;
            }
          }
        }
      }
    }

    &-page {
      height: 300px;
      @include breakpoint(l) {
        height: 440px;
      }

      .header {
        &__title {
          width: 100%;
          text-align: center;
          font-size: 11.2vw;
          line-height: 1.20;
          @include breakpoint(s) {
            font-size: 45px;
          }
          @include breakpoint(l) {
            font-size: 64px;
          }
          span {
            margin-top: 0.2em;
            display: block;
            font-size: 0.35em;
          }
        }

        &__background {
          background-image: url('../img/header/background-page.jpg');
        }
      }
    }

    &-home {
      padding: 135px 0 110px;
      height: auto;
      @include breakpoint(m) {
        padding: 165px 0 130px;
      }
      @include breakpoint(l) {
        padding: 200px 0 160px;
      }

      .header {
        &__title {
          text-align: left;
          font-size: 12vw;
          line-height: 1.20;
          z-index: 1;
          @include breakpoint(s) {
            font-size: 50px;
          }
          @include breakpoint(m) {
            font-size: 60px;
          }
          @include breakpoint(l) {
            font-size: 72px;
            max-width: 85%;
          }

          i {
            font-style: normal;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: calc(100% - 0.1em);
              height: 0.08em;
              left: 0;
              right: 0;
              background: $color-atomic-tangerine;
              z-index: -1;
            }
          }
        }
      }
    }
  }
}
