// Html
html {
  font-size: $html-font-size;
  overflow-x: hidden;
  overflow-y: auto;
}

// Body
body {
  -moz-osx-font-smoothing: $font-smoothing-moz;
  -webkit-font-smoothing: $font-smoothing-webkit;
  -webkit-tap-highlight-color: $tap-highlight-color;
  background-color: $document-background;
  color: $document-color;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  min-height: $body-height;
  min-width: $body-width;
  overflow-x: hidden;
  overflow-y: hidden;
  position: $body-position;
}

// Link
a {
  @include button-transition;
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

// Selection
::selection {
  @if $selection-background != false {
    background-color: $selection-background;
  }
  @if $selection-color != false {
    color: $selection-color;
  }
  @if $selection-shadow != false {
    text-shadow: $selection-shadow;
  }
}

// Selection in Firefox
::-moz-selection {
  @if $selection-background != false {
    background-color: $selection-background;
  }
  @if $selection-color != false {
    color: $selection-color;
  }
  @if $selection-shadow != false {
    text-shadow: $selection-shadow;
  }
}