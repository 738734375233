.dribbble {
  @include breakpoint(m) {
    @include flexbox-display;
    @include flexbox-flow(row wrap);
  }

  &__shot {
    display: block;
    position: relative;
    overflow: hidden;
    height: 0;
    width: 100%;
    padding-top: 75%;
    background-color: $color-iron;
    @include breakpoint(m) {
      @include flexbox-flex(0 0 50%);
      width: unset;
      padding-top: 37.5%;
    }

    img {
      @include transition(transform);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      img {
        transform: translateZ(0) scale(1.1);
      }
    }
  }
}