// Filterable
.filterable {
  position: relative;

  &__wrapper {
    position: relative;
    transform-origin: top center;
  }

  &__loader {
    @include transition(opacity);
    position: absolute;
    font-size: 20px;
    color: $color-persimmon;
    pointer-events: none;
    z-index: 5;
    top: 16px;
    line-height: 1;
    left: 50%;
    width: 32px;
    text-align: center;
    top: 0;
    opacity: 0;
    line-height: 32px;
    margin-left: -16px;
    margin-top: -16px;

    &.is-active {
      opacity: 1;
    }

    .icon {
      animation: spinner 1s linear infinite;
    }
  }

  &__pagination {
    ul {
      @extend .flexbox--center-row;
    }
  }

  .empty {
    @extend .paragraph--size-l;
  }
}