.modal {
  height: 100%;
  overflow: visible;
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  z-index: 200;

  &__overlay {
    background: rgba($color-shark, 0.75);
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;

    body.ie & {
      visibility: hidden;
    }
  }

  &__overlay-close {
    position: absolute;
    width: 0;
    right: calc(100% + #{$cnt-spacing});
    height: 100%;
    top: 0;
    opacity: 0;
    @include breakpoint(m) {
      right: calc(100% + #{$cnt-spacing-m});
    }

    .modal.is-active & {
      width: 100vw;
    }
  }

  &__wrapper {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    transform: translate3d(-100%, 0, 0);
    width: 100vw;

    > .cnt {
      position: relative;
    }

    &--type {
      &-background {
        left: 0;
        position: absolute;
        top: 0;

        .cnt {
          height: 100%;
        }
      }

      &-content {
        -webkit-overflow-scrolling: touch;

        .cnt {
          min-height: 100%;
        }
      }
    }
  }

  &__max-width {
    height: 100%;
    margin-left: auto;
    max-width: 320px;
    position: relative;
  }

  &__background {
    background: $color-aqua-haze;
    height: 100%;
    left: -$cnt-spacing;
    position: relative;
    width: 100vw;
    @include breakpoint(m) {
      left: -$cnt-spacing-m;
    }
  }

  &__content {
    @include flexbox-display;
    @include flexbox-flow(row nowrap);
    @include flexbox-align-items(center);
    min-height: 100vh;
    padding: 4rem 0;
    position: relative;
    z-index: 2;
    @include breakpoint(m) {
      padding: 5rem 0;
    }
    @include breakpoint(xxl) {
      padding: 6rem 0;
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
    max-width: 100%;
  }

  &__close {
    @include button-reset;
    @include transition(color);
    color: $color-mortar;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: 18px;
    z-index: 3;
    @include breakpoint(l) {
      font-size: 32px;
      top: 24px;
    }

    .icon {
      display: block;
    }

    &:hover {
      color: $color-atomic-tangerine;
    }
  }

  &--style {
    &-testimonial {
      .modal {
        &__max-width {
          max-width: #{320px - $cnt-spacing * 2}
        }

        &__inner {
          padding: 0 15px;
          @include breakpoint(m) {
            padding: 0 10px;
          }
        }

        &__testimonial {
          text-align: center;

          &-logo {
            @include contain;
            @extend .distance--bottom-s;
            height: 0;
            margin-left: auto;
            margin-right: auto;
            padding-top: 150px * 0.55;
            width: 150px;
          }

          &-avatar {
            @include contain;
            @extend .distance--bottom-xs;
            background-color: $color-white;
            border-radius: 100%;
            height: 150px;
            margin-left: auto;
            margin-right: auto;
            width: 150px;
            box-shadow: 0 0.1rem 0.6rem rgba($color-lynch, 0.1);
            @include breakpoint(m) {
              box-shadow: 0 0.2rem 0.8rem rgba($color-lynch, 0.1);
            }
          }

          &-name {
            @extend .distance--bottom-l;
            font-size: 16px;
            font-weight: 700;
            @include breakpoint(m) {
              font-size: 18px;
            }

            span {
              display: block;
              text-transform: uppercase;
              line-height: 1.3;
            }
          }

          &-text {
            @extend .distance--bottom-xl;
            font-size: 16px;
            @include breakpoint(m) {
              font-size: 18px;
            }
          }

          &-buttons {
            .button {
              width: 100%;

              &:not(:last-child) {
                margin-bottom: 1rem;
              }

              &--style {
                &-default {
                  z-index: 2;
                }

                &-outline {
                  z-index: 1;

                  &:before {
                    background-color: $color-aqua-haze;
                  }
                }
              }
            }
          }
        }
      }
    }

    &-form {
      .modal {
        &__max-width {
          max-width: 380px;
          @include breakpoint(m) {
            max-width: 400px;
          }
          @include breakpoint(l) {
            max-width: 100%;
          }
        }

        &__close {
          color: $color-white;
          @include breakpoint(l) {
            color: $color-mortar;
          }
        }

        &__background {
          &-image {
            @include cover;
            background-color: $color-haiti;
            background-image: url("../img/modal/background-get-started.jpg");
            height: calc(100% - 280px);
            left: -$cnt-spacing - 2px;
            position: absolute;
            top: 0;
            width: calc(100% + #{$cnt-spacing * 2} + 4px);
            z-index: 1;
            @include breakpoint(m) {
              left: -$cnt-spacing-m - 2px;
              height: calc(100% - 300px);
              width: calc(100vw - (100vw - #{$cnt-width-m}) / 2);
            }
            @include breakpoint(l) {
              height: 100%;
              left: -$cnt-spacing-m * 1.5;
              width: 770px;
            }
          }
        }

        &__parallax {
          transform: translate3d(0, 0, 0);
          @include breakpoint(l) {
            transform: translate3d(100%, 0, 0);
          }
        }

        &__form {
          @include breakpoint(l) {
            @include flexbox-display;
            @include flexbox-flow(row nowrap);
            @include flexbox-align-items(center);
          }

          .text-slider {
            height: 66px;
            @include breakpoint(m) {
              height: 72px;
            }
          }

          &-content {
            @include flexbox-display;
            @include flexbox-align-items(center);
            @include cover;
            margin-bottom: 2rem;
            position: relative;
            z-index: 1;
            @include breakpoint(l) {
              @include flexbox-flex(0 0 820px);
              margin-bottom: 0;
            }

            &-limit {
              max-width: $breakpoint-s - $cnt-spacing - $cnt-spacing;
              @include breakpoint(l) {
                max-width: 360px;
              }
            }
          }

          &-contact {
            background: $color-selago;
            border-radius: $border-radius;
            box-shadow: 0 15px 36px 0 rgba($color-black, 0.2);
            position: relative;
            z-index: 2;
            @include breakpoint(m) {
              box-shadow: 0 18px 44px 0 rgba($color-black, 0.2);
            }
            @include breakpoint(l) {
              @include flexbox-flex(0 0 550px);
              left: -410px;
            }

            &-page {
              padding: 38px 7%;
              @include breakpoint(s) {
                padding: 38px 28px;
              }
              @include breakpoint(m) {
                padding: 44.8px;
              }
              @include breakpoint(l) {
                padding: 55px;
              }

              .distance--bottom-l .icon {
                font-size: 48px;
              }

              form {
                .grid {
                  @include grid-distance(2rem, 0);

                  > div {
                    &:first-child {
                      margin-bottom: 22.4px;
                      @include breakpoint(m) {
                        margin-bottom: 27.2px;
                      }
                      @include breakpoint(l) {
                        margin-bottom: 0;
                      }
                    }
                  }
                }

                .button {
                  @include transition(background, opacity, color, filter);

                  &__spinner {
                    @include transition(width, opacity, transform);
                    display: inline-block;
                    text-align: left;
                    width: 0;
                    opacity: 0;
                    transform: translateX(-15px);

                    .icon {
                      animation: spinner 1s linear infinite;

                      @keyframes spinner {
                        from {
                          transform: rotate(0deg);
                        }
                        to {
                          transform: rotate(360deg);
                        }
                      }
                    }
                  }

                  &.is-busy {
                    filter: grayscale(1);
                    background-position: top left;
                    cursor: default;
                    opacity: 0.5;

                    .button {
                      &__spinner {
                        width: 30px;
                        opacity: 1;
                        transform: translateX(0);
                      }
                    }
                  }
                }
              }
            }
          }

          &-list {
            li {
              @extend .flexbox--center-row;
              @extend .distance--bottom-s;

              div {
                margin-left: 20px;
                @include flexbox-flex(0 1 auto);
              }

              .icon {
                @include flexbox-flex(0 0 auto);
                font-size: 32px;
              }
            }
          }
        }
      }

      &.is-active {
        .modal {
          &__parallax {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }

    &-become-amigo {
      .modal {
        &__form-list {
          li {
            position: relative;

            > div {
              margin-left: 18px;
              @include breakpoint(m) {
                margin-left: 24px;
              }
            }

            &:before {
              content: '\2022';
              color: $color-atomic-tangerine;
              position: absolute;
              left: 0;
              top: 0;
              line-height: 22px;
              @include breakpoint(m) {
                line-height: 24px;
              }
            }
          }
        }
      }
    }

    &-navigation {
      z-index: 3;
      height: 100vh;
      @include breakpoint(l) {
        display: none;
      }

      .modal {
        &__max-width {
          max-width: 230px;
        }

        &__overlay {
          height: 100vh;
        }

        &__background {
          @include gradient-vertical($color-bittersweet, $color-amaranth);
        }

        &__content {
          @include flexbox-align-items(flex-start);
          padding-top: 4rem;
          padding-bottom: 4rem;
        }

        &__navigation {
          @include flexbox-display;
          @include flexbox-flow(column nowrap);
          padding-left: 1.5rem;
          min-height: calc(100vh - 6rem);

          h2 {
            display: none;
          }

          nav {
            margin-bottom: 35px;

            ul {
              li {
                &:not(:last-child) {
                  margin-bottom: 18px;
                }

                > a {
                  display: block;
                  color: $color-white;
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 1;
                  white-space: nowrap;
                }
              }
            }
          }

          &-dropdown {
            @include transition(height, opacity, padding, transform);
            padding-left: 20px;
            height: 0;
            padding-top: 0;
            opacity: 0;
            overflow: hidden;
            transform: translateY(10px);

            &.is-active {
              height: 220px;
              opacity: 1;
              transform: translateY(18px);
            }
          }

          &-social {
            margin-bottom: 25px;
            margin-top: auto;

            &:before {
              content: '';
              display: block;
              width: 20px;
              height: 1px;
              background: $color-white;
              margin-bottom: 25px;
            }

            ul {
              @include flexbox-display;
              @include flexbox-flow(row wrap);

              li {
                margin-right: 1rem;

                a {
                  font-size: 16px;
                  color: $color-white;
                }
              }
            }
          }

          &-extra-links {
            font-size: 14px;

            a {
              display: block;
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

.modal {
  $ease-out: cubic-bezier(0.215, 0.61, 0.355, 1); // ease out cubic
  $ease-in: cubic-bezier(0.645, 0.045, 0.355, 1); // ease in out cubic
  $duration: 666ms;
  transition-duration: $duration;

  &__overlay,
  &__wrapper,
  &__parallax {
    transition-duration: $duration;
    transition-timing-function: $ease-in;
  }

  &.is-active .modal__overlay,
  &.is-active .modal__wrapper,
  &.is-active .modal__parallax {
    transition-timing-function: $ease-out;
  }

  &__wrapper {
    transform: translate3d(0, 0, 0);
    transition-property: transform;
    will-change: transform;
  }

  &__overlay {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition-property: opacity;
    will-change: opacity;

    body.ie & {
      transition-property: opacity, visibility;
      will-change: opacity, visibility;
    }
  }

  &__parallax {
    transform: translate3d(50vw, 0, 0);
    transition-property: transform;
    will-change: transform;
  }

  &.is-active {
    .modal {
      &__overlay {
        opacity: 1;

        body.ie & {
          visibility: visible;
        }
      }

      &__wrapper {
        transform: translate3d(-100%, 0, 0);
      }

      &__parallax {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &.is-disabled {
    .modal {
      &__overlay {
        opacity: 0;
        transition: none;
      }

      &__wrapper {
        transition: none;
        transform: translate3d(0, 0, 0);
      }

      &__parallax {
        transition: none;
        transform: translate3d(50vw, 0, 0);
      }
    }
  }
}
