// Checkbox
.checkbox {
  cursor: pointer;
  position: relative;
  font-size: 0;
  display: block;

  &--style {
    &-default {
      padding-left: 1.125rem;

      .checkbox {
        &__icon {
          border-radius: $border-radius;
          border-style: solid;
          border-width: 0.063rem;
          font-size: 0.75rem;
          min-height: 1.125rem;
          min-width: 1.125rem;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    vertical-align: top;
    width: 16px;
    height: 16px;
    border: 1px solid $color-mountain-mist;
    border-radius: 3px;

    &:after {
      @include transition(opacity, transform);
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      bottom: 2px;
      right: 2px;
      background: $color-atomic-tangerine;
      border-radius: 2px;
      opacity: 0;
      transform: scale(0.5);
    }
  }

  &__label {
    @extend .no-select;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    text-transform: uppercase;
    line-height: 16px;
    font-size: 12px;
    padding-left: 24px;
    color: $color-mountain-mist;

    span {
      color: $color-atomic-tangerine;
    }
  }

  &__helper {
    display: none;

    &:checked + .checkbox__icon {
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
