// Payment
.payment {
  position: relative;
  background: $color-aqua-haze;
  min-height: calc(100vh - 80px);

  .cnt {
    @include flexbox-display;
    @include flexbox-align-items(center);
    width: 100%;
    min-height: calc(100vh - 80px);
  }

  &__background {
    @include cover;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: $color-haiti;
    background-image: url(../img/modal/background-get-started.jpg);
    right: 0;
    @include breakpoint(m) {
      right: calc((100vw - #{$cnt-width-m}) / 2 + #{$cnt-spacing-m} + 140px);
    }
    @include breakpoint(l) {
      width: calc((100vw - #{$cnt-width-l}) / 2 + 770px);
    }
  }

  &__container {
    position: relative;
    z-index: 2;
    padding: 4rem 0;
    width: 100%;
    @include breakpoint(m) {
      @include flexbox-display;
      @include flexbox-flow(row nowrap);
      @include flexbox-align-items(flex-start);
      padding: 5rem 0;
    }
    @include breakpoint(l) {
      padding: 5rem 0;
    }
    @include breakpoint(xxl) {
      padding: 6rem 0;
    }
  }

  &__contact {
    display: none;
    @include breakpoint(m) {
      display: block;
      width: 100%;
      color: $color-white;
      font-size: 13px;
      width: 260px;
      padding-right: 2rem;
    }
    @include breakpoint(l) {
      width: 300px;
      padding-right: 2.5rem;
    }

    &-group {
      @extend .distance--bottom-m;
    }

    &-padding {
      padding-left: 1rem;
    }

    &-social {
      font-size: 1rem;

      ul {
        @extend .flexbox--center-row;

        li {
          margin-right: 1.5rem;
        }
      }
    }

    .divider {
      @extend .divider--size-small;
      @extend .divider--color-white;
      @extend .distance--bottom-s;
    }

    p {
      @extend .distance--bottom-s;
    }

    a {
      display: block;
    }
  }

  &__form {
    position: relative;
    background: $color-selago;
    border-radius: $border-radius;
    box-shadow: 0 15px 36px 0 rgba($color-black, 0.2);
    width: 100%;
    @include breakpoint(m) {
      width: calc(100% - 260px);
      box-shadow: 0 18px 44px 0 rgba($color-black, 0.2);
    }
    @include breakpoint(l) {
      width: calc(100% - 300px);
    }

    &-page {
      padding: 38px 28px;
      @include breakpoint(s) {
        padding: 38px 28px;
      }
      @include breakpoint(m) {
        padding: 44.8px;
      }
      @include breakpoint(l) {
        padding: 55px;
      }
    }

    form {
      .grid {
        @include grid-distance(2rem, 0);

        > div {
          &:first-child {
            margin-bottom: 22.4px;
            @include breakpoint(m) {
              margin-bottom: 27.2px;
            }
            @include breakpoint(l) {
              margin-bottom: 0;
            }
          }
        }
      }

      .button {
        @include transition(background, opacity, color, filter);

        &__spinner {
          @include transition(width, opacity, transform);
          display: inline-block;
          text-align: left;
          width: 0;
          opacity: 0;
          transform: translateX(-15px);

          .icon {
            animation: spinner 1s linear infinite;

            @keyframes spinner {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          }
        }

        &.is-busy {
          filter: grayscale(1);
          background-position: top left;
          cursor: default;
          opacity: 0.5;

          .button {
            &__spinner {
              width: 30px;
              opacity: 1;
              transform: translateX(0);
            }
          }
        }
      }
    }
  }

  &__info {
    @include flexbox-display;
    @include flexbox-flow(row nowrap);
    @include flexbox-align-items(space-between);
    @extend .paragraph--size-m;
    @extend .distance--bottom-xs;
    line-height: 40px;
    border-bottom: 1px solid $color-mountain-mist;

    &-name {
      margin-right: auto;
    }

    &-total {
      font-weight: 600;
    }

    &-value {
      font-weight: 600;
      margin-left: 120px;
    }

    &-card {
      @extend .paragraph--size-m;
      text-align: right;
      font-weight: 600;

      .icon {
        font-size: 120%;
        margin-left: 16px;
      }
    }
  }
}