// Pagination
.pagination {
  font-size: 15px;
  @include breakpoint(m) {
    font-size: 16px;
  }

  li {
    margin: 0 14px;
    @include breakpoint(m) {
      margin: 0 16px;
    }

    &.is-active {
      a {
        color: $color-persimmon;
      }
    }

    &.is-disabled {
      color: $color-mountain-mist;
    }

    a {
      position: relative;
      display: block;

      &:before {
        @include transition(opacity);
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: -1;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        margin-left: -15px;
        margin-top: -15px;
        background: linear-gradient(to bottom right, $color-bittersweet, $color-amaranth);
        opacity: 0;
      }

      &:hover {
        color: $color-white;

        &:before {
          opacity: 1;
        }
      }
    }

    &.prev,
    &.next {
      a {
        &:hover {
          color: $color-persimmon;
        }

        &:before {
          content: none;
          display: none;
        }
      }
    }

    &.prev {
      margin-left: auto;
      margin-right: 20px;
      @include breakpoint(m) {
        margin-left: auto;
        margin-right: 34px;
      }
    }

    &.next {
      margin-right: auto;
      margin-left: 20px;
      @include breakpoint(m) {
        margin-left: 34px;
        margin-right: 0;
      }
    }
  }
}