// Prism

code[class*="language-"],
pre[class*="language-"] {
  -moz-hyphens: none;
  -moz-tab-size: 4;
  -ms-hyphens: none;
  -o-tab-size: 4;
  -webkit-hyphens: none;
  background: $color-aqua-haze;
  color: $color-dolphin;
  direction: ltr;
  font-family: Consolas, Menlo, Monaco, Courier, monospace;
  font-size: 13px;
  hyphens: none;
  line-height: 1.5;
  tab-size: 4;
  text-align: left;
  white-space: pre;
  word-break: normal;
  word-spacing: normal;
  @include breakpoint(m) {
    font-size: 14px;
  }

  &::selection,
  ::selection {
    background: $color-atomic-tangerine;
  }

  &::-moz-selection,
  ::-moz-selection {
    background: $color-atomic-tangerine;
  }
}

pre[class*="language-"] {
  -webkit-overflow-scrolling: touch;
  margin: 0.5em 0;
  overflow: auto;
  padding: 1em;
}

:not(pre) > code[class*="language-"] {
  border-radius: 0.3em;
  padding: 0.1em;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: lighten($color-mountain-mist, 10%);
}

.token.punctuation {
  color: lighten($color-mountain-mist, 10%);
}

.token.namespace {
  opacity: 0.7;
}

.token.tag,
.token.operator,
.token.number {
  color: darken($color-mountain-mist, 10%);
}

.token.property,
.token.function {
  color: $color-dolphin;
}

.token.tag-id,
.token.comment,
.token.atrule-id {
  color: lighten($color-mountain-mist, 10%);
}

code.language-javascript,
.token.attr-name {
  color: $color-atomic-tangerine;
}

code.language-css,
code.language-scss,
.token.boolean,
.token.string,
.token.entity,
.token.url,
.language-css .token.string,
.language-scss .token.string,
.style .token.string,
.token.attr-value,
.token.keyword,
.token.control,
.token.directive,
.token.unit,
.token.statement,
.token.regex,
.token.atrule {
  color: $color-persimmon;
}

.token.placeholder,
.token.variable {
  color: $color-persimmon;
}

.token.deleted {
  text-decoration: line-through;
}

.token.inserted {
  border-bottom: 1px dotted lighten($color-mountain-mist, 10%);
  text-decoration: none;
}

.token.italic {
  font-style: italic;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.important {
  color: $color-amaranth;
}

.token.entity {
  cursor: help;
}

pre > code.text {
  outline: 0.4em solid $color-dolphin;
  outline-offset: 0.4em;
}

.line-numbers-rows > span:before {
  color: lighten($color-mountain-mist, 10%);
}

// Line Numbers

pre[class*="language-"].line-numbers {
  counter-reset: linenumber;
  padding-left: 3.8em;
  position: relative;
}

pre[class*="language-"].line-numbers > code {
  position: relative;
  white-space: inherit;
}

.line-numbers .line-numbers-rows {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  font-size: 100%;
  left: -3.8em;
  letter-spacing: -1px;
  pointer-events: none;
  position: absolute;
  top: 0;
  user-select: none;
  width: 3em;

  > span {
    counter-increment: linenumber;
    display: block;
    pointer-events: none;

    &:before {
      content: counter(linenumber);
      display: block;
      padding-right: 0.8em;
      text-align: right;
    }
  }
}
