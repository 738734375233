// Field
.field {
  -moz-osx-font-smoothing: $font-smoothing-moz;
  -webkit-font-smoothing: $font-smoothing-webkit;
  border-radius: 0;
  display: block;
  width: 100%;
  @include placeholder {
    opacity: 1;
  }

  &--style {
    &-default {
      @include transition(border-color, color);
      background: transparent;
      border: none;
      border-bottom: 1px solid $color-mountain-mist;
      color: $color-haiti;
      font-family: $font-montserrat;
      font-size: 16px;
      padding: 6px 0;
      line-height: 24px;
      @include breakpoint(m) {
        font-size: 18px;
        padding: 8px 0;
        line-height: 26px;
      }
      @include placeholder {
        color: rgba($color-haiti, 0.33);
      }

      &:focus {
        border-bottom-color: $color-atomic-tangerine;
      }

      @at-root textarea#{&} {
        min-height: 85px;
        @include breakpoint(m) {
          min-height: 95px;
        }
      }
    }
  }
}