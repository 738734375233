// Grid
.grid {
  @include flexbox-display;
  @include flexbox-wrap(wrap);

  &--invert {
    @include flexbox-flow(row-reverse wrap);
  }

  &--middle {
    @include flexbox-align-items(center);
  }

  &--columns {
    @for $i from 1 through 6 {
      &-#{$i} {
        > * {
          @include flexbox-flex(0 0 100%/$i);
          max-width: 100%/$i;
        }
      }
      @each $breakpoint in (s, m, l) {
        &-#{$breakpoint}-#{$i} {
          @include breakpoint($breakpoint) {
            > * {
              @include flexbox-flex(0 0 100%/$i);
              max-width: 100%/$i;
            }
          }
        }
      }
    }
  }
}

@mixin grid-distance($a: 0, $b: null) {
  @if $b == null { $b: $a; }
  margin: 0 -#{$a} -#{$b} 0;

  > * {
    padding: 0 #{$a} #{$b} 0;
  }
}