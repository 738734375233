// Easing
$ease-in-quad:              cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad:             cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad:          cubic-bezier(0.455, 0.03, 0.515, 0.955);

// Animation
$animation-duration:        300ms;
$animation-easing:          $ease-in-out-quad;

// Body
$body-position:             static;
$body-height:               100vh;
$body-width:                320px;

// Border radius
$border-radius:             4px;

// Breakpoint
$breakpoint-s:              425px;  // mobile > s < tablet
$breakpoint-m:              768px;  // tablet portrait
$breakpoint-l:              1024px; // tablet landscape
$breakpoint-xl:             1200px; // laptop 1366
$breakpoint-2xl:            1440px; // desktop > 1440

// Cnt
$cnt-spacing:               20px;
$cnt-spacing-m:             32px;
$cnt-width-s:               100%;
$cnt-width-m:               768px;
$cnt-width-l:               1024px;

// Color
$color-atomic-tangerine:    #ff945f;
$color-bittersweet:         #fa7e5a;
$color-persimmon:           #ff6a58;
$color-burnt-sienna:        #ee5f5c;
$color-amaranth:            #eb3c4b;
$color-mountain-meadow:     #17b161;
$color-haiti:               #140c2f;
$color-lynch:               #727fa0;
$color-black:               #000000;
$color-shark:               #1c1f27;
$color-dolphin:             #666677;
$color-mortar:              #524a54;
$color-mountain-mist:       #a7a1a9;
$color-alto:                #dbdbdb;
$color-iron:                #d0d7da;
$color-aqua-haze:           #e8eef2;
$color-tuft-bush:           #ffdfcf;
$color-catskill-white:      #ecf1f7;
$color-selago:              #f8f9fe;
$color-white:               #ffffff;
$color-boulder:             #7c7c7c;
$color-scorpion:            #615c5c;
$color-tundora:             #463e3e;
$color-dusty-gray:          #979797;
$color-valhalla:            #2F175A;

// Document
$document-background:       $color-selago;
$document-color:            $color-shark;

// Font families
$font-montserrat:           'Montserrat', sans-serif;
$font-roboto:               'Roboto', sans-serif;

// Font options
$font-family:               $font-montserrat;
$font-size:                 18px;
$font-smoothing-moz:        grayscale;
$font-smoothing-webkit:     antialiased;
$font-weight:               400;

// Html
$html-font-size:            16px;

// Line height
$line-height:               1.6;

// Link
$link-color:                inherit;
$link-decoration:           none;
$link-hover-color:          $color-persimmon;
$link-hover-decoration:     none;

// Selection
$selection-background:      $color-atomic-tangerine;
$selection-color:           $color-white;
$selection-shadow:          false;

// Tap Highlight Color
$tap-highlight-color:       rgba($color-white, 0);
